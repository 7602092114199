import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'

export default function AlpacaMigrationMessage(props) {
  const renderMessage = () => {
    switch (props.alpacaMigrationEligible) {
      case 0:
        return (
          <div>
            <p>
              {props?.t('accountnoteligibleformigration')}{' '}
              <a href='mailto: help@investall.ai'>help@investall.ai.</a>
            </p>
          </div>
        )
      case 1:
        return (
          <div>
            <p>{props?.t('accountsetupincomplete')}</p>
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <h1>{props?.t('alpacamigrationtitle')}</h1>
            <p>1. {props?.t('instructionstocontinuetrading')}</p>
            <p>2. {props?.t('createnewalpacaaccountdetails')}</p>
            <p>3. {props?.t('assettransferinstructions')}</p>
            <p>*{props?.t('importantnoteaboutaccountstatus')}*</p>
          </div>
        )
    }
  }

  const renderButton = () => {
    switch (props.alpacaMigrationEligible) {
      case 1:
        return (
          <Button
            onClick={() => props.toggleModal()}
            label={props?.t('continue')}
          />
        )
      case 0:
        return (
          <Button
            onClick={() => props.toggleModal()}
            label={props?.t('okay')}
          />
        )
      default:
        return (
          <Button
            onClick={() => {
              props.showAddData()
              props.toggleModal()
            }}
            label={props.t('getstarted')}
          />
        )
    }
  }
  return (
    <Dialog
      visible={props?.showModal}
      draggable={false}
      header={props?.t('alpacaaccountmigration')}
      className='alpaca-migration-modal'
      onHide={() => props?.toggleModal()}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 20,
          gap: 12,
        }}
      >
        {renderMessage()}
        {renderButton()}
      </div>
    </Dialog>
  )
}
