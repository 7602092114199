import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { MultiSelect } from 'primereact/multiselect'
import BaseURL from './Endpoint'
import axios from 'axios'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { Toast } from 'primereact/toast'
import '../scss/components/IBQuestionnaire.scss'
import shortTermImg from '../assets/short-term.svg'
import mediumTermImg from '../assets/medium-term.svg'
import longTermImg from '../assets/long-term.svg'
import { Steps } from 'primereact/steps'
import { Panel } from 'primereact/panel'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'
import { Skeleton } from 'primereact/skeleton'
import { SelectButton } from 'primereact/selectbutton'
import { useHistory } from 'react-router-dom'
import { InputTextarea } from 'primereact/inputtextarea'
import IBKYC from './IBKYC'

import { Tag } from 'primereact/tag'
import '../scss/components/IBKYC.scss'
import PDFImg from '../assets/PDF_file_icon.svg.png'
import { FileUpload } from 'primereact/fileupload'
import SearchEndpoint from './SearchEndpoint'
import Endpoint from './Endpoint'

const SkeletonLoading = () => {
  return (
    <>
      <Skeleton className='mb-2'></Skeleton>
      <Skeleton width='10rem' className='mb-2'></Skeleton>
      <Skeleton width='5rem' className='mb-2'></Skeleton>
      <Skeleton height='2rem' className='mb-2'></Skeleton>
      <Skeleton width='10rem' height='4rem'></Skeleton>
    </>
  )
}

const IBQuestionnaireModel = (props) => {
  const [IBFormInfo, setIBFormInfo] = useState({
    investment_objectives: [],
  })

  // console.log(prefinraData)

  // console.log('allCountries', props?.allCountries)

  // console.log('ibformodal', props?.ibFormModel)
  // if (props?.closeable) {
  //   props?.setIbFormModel(true)
  // }

  const toastErrorMsg = useRef()

  //step index for the questionnaire model
  const [activeIndex, setActiveIndex] = useState(0)
  const [lastStepIndex, setLastStepIndex] = useState(0)

  // toggle status for the form to dynamic render or not render components
  const [optionsToggleStatus, setOptionsToggleStatus] = useState('Yes')
  const [bondsToggleStatus, setBondsToggleStatus] = useState('No')
  const [isSameAddress, setIsSameAddress] = useState(false)
  const [agreeIBPaperwork, setAgreeIBPaperwork] = useState(false)
  const [agreeForescitePaperwork, setAgreeForescitePaperwork] = useState(false)
  const [agreeTaxFormPaperwork, setAgreeTaxFormPaperwork] = useState(false)
  const [agreeAlpacaRead, setAgreeAlpacaRead] = useState(false)
  const [agreeAlpacaSign, setAgreeAlpacaSign] = useState(false)
  const [alpacaInternationalAgree1, setAlpacaInternationalAgree1] =
    useState(false)
  const [alpacaInternationalAgree2, setAlpacaInternationalAgree2] =
    useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [incomeSources, setIncomeSources] = useState([
    { is_used_for_funds: true, source_type: '', percentage: undefined },
  ])
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [countryTaxId, setCountryTaxId] = useState(null)
  const [countryTaxIdDefault, setCountryTaxIdDefault] = useState(null)

  // dynamic options variable from the api call
  const [publicIP, setPublicIp] = useState('')
  const [allCountries, setAllCountries] = useState(null)
  const [supportedCryptoStates, setSupportedCryptoStates] = useState(null)
  // const [employmentStatusOptions, setEmploymentStatusOptions] = useState(null)
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState(null)
  const [employmentPositionOptions, setEmploymentPositionOptions] =
    useState(null)
  const [americaStates, setAmericaStates] = useState(null)

  // documents variable
  const [optionsDetail, setOptionsDetail] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})

  const [documentsExpiration, setDocumentsExpiration] = useState(null)
  const [addressDocExpiration, setAddressDocExpiration] = useState(null)

  const [selectedOptionsDoc, setSelectedOptionsDoc] = useState({})
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentType, setDocumentType] = useState(null)

  // loading state for the fetching the dynamic options from api call
  const [isLoadingData, setIsLoadingData] = useState(true)

  const [isEligibilityWrong, setIsEligibilityWrong] = useState(false)

  const [submitFormButtonClick, setSubmitFormButtonClick] = useState(false)

  const [checkpointPrefillData, setCheckpointPrefillData] = useState({})

  const employmentStatusOptions = [
    { label: props?.t('unemployed'), value: 'UNEMPLOYED' },
    { label: props?.t('employed'), value: 'EMPLOYED' },
    { label: props?.t('student'), value: 'STUDENT' },
    { label: props?.t('retired'), value: 'RETIRED' },
  ]
  // static option list
  const maritalStatusOptions = [
    {
      label: props?.t('single'),
      value: 'SINGLE',
    },
    {
      label: props?.t('married'),
      value: 'MARRIED',
    },
    {
      label: props?.t('widowed'),
      value: 'WIDOWED',
    },
    {
      label: props?.t('divorced'),
      value: 'DIVORCED',
    },
    // {
    //   label: 'Common law partner',
    //   value: 'C',
    // },
  ]
  const sourceOfWealthOptions = [
    { label: props?.t('employmentincome'), value: 'employment_income' },
    { label: props?.t('investments'), value: 'investments' },
    { label: props?.t('inheritance'), value: 'inheritance' },
    { label: props?.t('businessincome'), value: 'business_income' },
    { label: props?.t('savings'), value: 'savings' },
    { label: props?.t('family'), value: 'family' },
  ]
  const toggleButtonOptions = ['Yes', 'No']
  const knowledgeOptions = [
    { label: props?.t('none'), value: 'none' },
    { label: props?.t('limited'), value: 'limited' },
    { label: props?.t('intermediate'), value: 'intermediate' },
    { label: props?.t('sophisticated'), value: 'sophisticated' },
  ]
  const InvestmentOptionObjList = [
    { label: props?.t('generateincome'), value: 'generate_income' },
    {
      label: props?.t('partiallyfundretirement'),
      value: 'partially_fund_retirement',
    },
    {
      label: props?.t('whollyfundretirement'),
      value: 'wholly_fund_retirement',
    },
    {
      label: props?.t('steadilyaccumulatewealth'),
      value: 'steadily_accumulate_wealth',
    },
    { label: props?.t('preservewealth'), value: 'preserve_wealth' },
    { label: props?.t('payforlargepurchase'), value: 'pay_for_large_purchase' },
    { label: props?.t('marketspeculation'), value: 'market_speculation' },
  ]
  // const InvestmentNoOptionObjeList = [
  //   {
  //     label: 'Preservation of Capital',
  //     value: 'Preservation',
  //   },
  //   {
  //     label: 'Trading Profits',
  //     value: 'Trading',
  //   },
  //   {
  //     label: 'Income',
  //     value: 'Income',
  //   },
  //   {
  //     label: 'Growth',
  //     value: 'Growth',
  //   },
  //   {
  //     label: 'Hedging',
  //     value: 'Hedging',
  //   },
  //   {
  //     label: 'Speculation',
  //     value: 'Speculation',
  //   },

  //   // 'Preservation of Capital',
  //   // 'Trading Profits',
  //   // 'Income',
  //   // 'Growth',
  //   // 'Hedging',
  //   // 'Speculation',
  // ]

  const FinancialNetWorthList = [
    { label: '0 - $20,000', value: '0' },
    { label: '$20,000 - $49,999', value: '1' },
    { label: '$50,000 - $99,999', value: '2' },
    { label: '$100,000 - $499,999', value: '3' },
    { label: '$500,000 - $999,999', value: '4' },
    { label: '$1,000,000 - $9,999,999', value: '5' },
  ]

  const liquidityNeeds = [
    { label: props?.t('veryimportant'), value: 'very_important' },
    { label: props?.t('important'), value: 'important' },
    { label: props?.t('somewhatimportant'), value: 'somewhat_important' },
    { label: props?.t('doesnotmatter'), value: 'does_not_matter' },
  ]

  const FinancialAnnualList = [
    { label: '0 - $20,000', value: '0' },
    { label: '$20,000 - $49,999', value: '1' },
    { label: '$50,000 - $99,999', value: '2' },
    { label: '$100,000 - $499,999', value: '3' },
    { label: '$500,000 - $999,999', value: '4' },
    { label: '$1,000,000 - $9,999,999', value: '5' },
  ]

  const PlanInvestmentList = [
    { label: props?.t('lessthan1year'), value: 'less_than_1_year' },
    { label: props?.t('onetotwoyears'), value: '1_to_2_years' },
    { label: props?.t('threetofiveyears'), value: '3_to_5_years' },
    { label: props?.t('sixtotenyears'), value: '6_to_10_years' },
    { label: props?.t('morethan10years'), value: 'more_than_10_years' },
  ]

  const RiskToleranceList = [
    { label: props?.t('conservative'), value: 'conservative' },
    { label: props?.t('moderate'), value: 'moderate' },
    { label: props?.t('significantrisk'), value: 'significant_risk' },
  ]

  const numDependents = [
    { label: '0', value: '0' },
    { label: '1', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ]

  const IBFormSteps = [
    {
      label: props?.t('personal'),
    },
    {
      label: props?.t('employment'),
    },
    {
      label: props?.t('financial'),
    },
    {
      label: props?.t('investments'),
    },
    {
      label: props?.t('acknowledgment'),
    },
    {
      label: props?.t('review'),
    },
  ]

  const alpacaVisaTypes = [
    {
      label: 'B1',
      value: 'B1',
    },
    {
      label: 'B2',
      value: 'B2',
    },
    {
      label: 'DACA',
      value: 'DACA',
    },
    {
      label: 'E1',
      value: 'E1',
    },
    {
      label: 'E2',
      value: 'E2',
    },
    {
      label: 'E3',
      value: 'E3',
    },
    {
      label: 'F1',
      value: 'F1',
    },
    {
      label: 'G4',
      value: 'G4',
    },
    {
      label: 'H1B',
      value: 'H1B',
    },
    {
      label: 'J1',
      value: 'J1',
    },
    {
      label: 'L1',
      value: 'L1',
    },
    {
      label: 'O1',
      value: 'O1',
    },
    {
      label: 'TN1',
      value: 'TN1',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ]

  const [dashboardLoading, setDashboardLoading] = useState(false)

  const [prefinraData, setPrefinraData] = useState({})

  const getPreFinraInfo = async () => {
    try {
      let res = await axios.get(`${BaseURL}/mobile/get-saved-information`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setPrefinraData(res?.data)

      return res?.data
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  useEffect(() => {
    async function init() {
      await fetchIP()
      await fetchPresets()
      await fetchIBDocumentsDetails()
      await fetchAlpacaPresets()
      await fetchCheckpointData()
    }
    init()
  }, [])

  useEffect(() => {
    if (Object?.keys(checkpointPrefillData)?.length > 0) {
      fillOutInformation()
    }
  }, [checkpointPrefillData])

  // reset the to empty option when change between option to no option for investment
  useEffect(() => {
    if (optionsToggleStatus) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['investment_objectives']: [],
      }))
    }
  }, [optionsToggleStatus])

  // reset the to empty bonds when change between bonds to no bonds for investment
  useEffect(() => {
    if (bondsToggleStatus) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['investment_objectives']: [],
        ['bonds_knowledge_level']: '',
        ['bonds_trades_per_year']: '',
        ['bonds_years_trading']: '',
      }))
    }
  }, [bondsToggleStatus])

  // empty the list when the toggle is off
  useEffect(() => {
    if (!isEligibilityWrong) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['affiliations_description']: '',
      }))
    }
  }, [isEligibilityWrong])

  useEffect(() => {
    if (activeIndex) {
      setFormErrorMessage('')
    }
  }, [activeIndex])

  const fillOutInformation = () => {
    const employerAddressArray =
      checkpointPrefillData?.emp_address?.split('|') || []

    let fill_data = {
      street_1: checkpointPrefillData?.street,
      street_2: checkpointPrefillData?.street2,
      city: checkpointPrefillData?.city,
      postal_code: checkpointPrefillData?.zip,
      citizenship: checkpointPrefillData?.country_citizenship,
      country_of_birth: checkpointPrefillData?.country_birth,
      marital_status: checkpointPrefillData?.marital_status,
      visa_type: checkpointPrefillData?.visa_type,
      no_dependents: checkpointPrefillData?.dependents,
      employment_status: checkpointPrefillData?.emp_status,
      company_name: checkpointPrefillData?.emp_name,
      employment_position: checkpointPrefillData?.emp_position,
      annual_net_income: checkpointPrefillData?.annual_income,
      net_worth: checkpointPrefillData?.net_worth,
      liquid_net_worth: checkpointPrefillData?.liquid_net_worth,
      liquidity_needs: checkpointPrefillData?.liquidity_needs,
      primary_funds: checkpointPrefillData?.funding_source?.[0],
      investment_plan: checkpointPrefillData?.investment_period,
      risk_tolerance: checkpointPrefillData?.risk_tolerance,
      knowledge_level: checkpointPrefillData?.experience_stocks,
      options_knowledge_level: checkpointPrefillData?.experience_options,
      investment_objective: checkpointPrefillData?.investment_objectives,
      employer_address_street_1: employerAddressArray[0] || null,
      employer_address_street_2: employerAddressArray[1] || null,
      employer_address_city: employerAddressArray[2] || null,
      employer_address_state: employerAddressArray[3] || null,
      employer_address_postal_code: employerAddressArray[4] || null,
      employer_address_country: employerAddressArray[5] || null,
      // international_employer_country: !prefinraData?.payload?.ustaxpayer
      //   ? employerAddressArray[6] || ''
      //   : '',
      citizenship_confirmation: checkpointPrefillData?.visa_status,
      state: checkpointPrefillData?.province,
    }

    // Set the form information
    setIBFormInfo(fill_data)
  }

  const fetchCheckpointData = async () => {
    try {
      const prefinra_data = await getPreFinraInfo()

      let { data } = await axios.get(
        Endpoint + '/mobile/alpaca/account/checkpoint/fetch',
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('[PrefinraQuestions] prefill data', data)
      console.log('prefinra_data', prefinra_data)
      let checkpointPrefillData = data.output ?? {}
      checkpointPrefillData = {
        ...checkpointPrefillData,
        birth_day: prefinra_data?.payload?.birth_day,
        birth_month: prefinra_data?.payload?.birth_month,
        birth_year: prefinra_data?.payload?.birth_year,
        ustaxpayer: prefinra_data?.payload?.ustaxpayer,
        first_name: prefinra_data?.payload?.first_name,
        last_name: prefinra_data?.payload?.last_name,
        phone: prefinra_data?.payload?.phone,
      }
      console.log('checkpointPrefillData', checkpointPrefillData)
      setCheckpointPrefillData(checkpointPrefillData)
    } catch (error) {
      console.log('Failed to fetch prefill data', error)
      setCheckpointPrefillData({})
    }
  }

  const submitCheckpointData = async () => {
    const employment_address = [
      IBFormInfo?.employer_address_street_1,
      IBFormInfo?.employer_address_street_2,
      IBFormInfo?.employer_address_city,
      IBFormInfo?.employer_address_state,
      IBFormInfo?.employer_address_postal_code,
      IBFormInfo?.employer_address_country,
    ]

    const employment_address_value =
      IBFormInfo?.employment_status === 'EMPLOYED'
        ? employment_address.join('|')
        : null
    console.log('checkpointPrefillData', checkpointPrefillData)
    let body = {
      dob_day: checkpointPrefillData.birth_day,
      dob_month: checkpointPrefillData.birth_month,
      dob_year: checkpointPrefillData.birth_year,
      ustaxpayer: checkpointPrefillData.us_tax_payer,
      first_name: checkpointPrefillData.first_name,
      last_name: checkpointPrefillData.last_name,
      phone: checkpointPrefillData.phone,
      address_1: IBFormInfo?.street_1,
      address_2: IBFormInfo?.street_2,
      city: IBFormInfo?.city,
      state: IBFormInfo?.state,
      zip: IBFormInfo?.postal_code,
      country_residence: IBFormInfo?.international_country_address,
      tax_id_type: prefinraData?.payload?.ustaxpayer
        ? 'USA_SSN'
        : IBFormInfo?.tax_id_type,
      country_citizenship: IBFormInfo?.citizenship,
      country_birth: IBFormInfo?.country_of_birth,
      marital_status: IBFormInfo?.marital_status,
      dependents: IBFormInfo?.no_dependents,
      visa_status: IBFormInfo?.citizenship_confirmation,
      employment_status: IBFormInfo?.employment_status,
      employer_name: IBFormInfo?.company_name,
      employment_position: IBFormInfo?.employment_position,
      annual_income: IBFormInfo?.annual_net_income,
      net_worth: IBFormInfo?.net_worth,
      liquid_net_worth: IBFormInfo?.liquid_net_worth,
      investment_period: IBFormInfo?.investment_plan,
      risk_tolerance: IBFormInfo?.risk_tolerance,
      investment_objectives: IBFormInfo?.investment_objective,
      employer_address: employment_address_value ?? null,
      visa_type: IBFormInfo?.visa_type,
      experience_stocks: IBFormInfo?.knowledge_level,
      experience_options: IBFormInfo?.options_knowledge_level,
      liquidity_needs: IBFormInfo?.liquidity_needs,
      funding_source: IBFormInfo?.primary_funds,
    }
    console.log('body', body)
    let { data } = await axios.post(
      Endpoint + '/mobile/alpaca/account/checkpoint/save',
      body,
      {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      }
    )
    console.log(data)
  }

  const fetchIBDocumentsDetails = async () => {
    try {
      let res = await axios.get(`${BaseURL}/ibkr/document-preset`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log('document-preset', res?.data)

      setOptionsDetail(res?.data)
      //  setPageloading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchIP = async () => {
    try {
      const res = await axios.get('https://api.ipify.org?format=json')
      console.log('ip', res?.data?.ip)
      if (res?.data?.ip) {
        setPublicIp(res?.data?.ip)
        return true
      }
      console.log('ip data not found', res?.data)
      return false
    } catch (error) {
      console.log('there is an error fetching the ip', error)
      return false
    }
  }

  const fetchAlpacaPresets = async () => {
    try {
      let res = await axios.get(`${BaseURL}/mobile/alpaca/preset-data`)
      console.log('alpaca-preset', res.data)
      let allCountries = []
      allCountries = res.data.supported_country_list.map((country) => {
        if (country.full_name === 'China') {
          console.log('China', country)
          return {
            value: country.country_code,
            label: 'China/中国',
          }
        } else {
          return {
            value: country.country_code,
            label: country.full_name,
          }
        }
      })
      setAllCountries(allCountries)

      const usaObject = res?.data?.supported_country_list?.find(
        (country) => country.country_code === 'USA'
      )

      if (usaObject) {
        const cryptoSupportedStates = usaObject.crypto_supported_states
        setSupportedCryptoStates(cryptoSupportedStates)
      } else {
        setSupportedCryptoStates([])
      }

      let countries_tax_id = {}
      let default_tax_ids = res.data.tax_id_defaults.list.map((item) => ({
        value: item.code,
        label: props?.t(item.code?.toLowerCase()),
      }))
      // if there are additional methods for tax id for a specific country
      res.data.tax_id_countries.forEach((country) => {
        countries_tax_id[country.country_code] = [
          ...country.list.map((item) => ({
            value: item.code,
            label: props?.t(item.code?.toLowerCase()),
          })),
          ...default_tax_ids,
        ]
      })
      setCountryTaxId(countries_tax_id)
      setCountryTaxIdDefault(default_tax_ids)
    } catch (error) {
      console.log('alpaca-preset ERROR: ', error)
      setCountryTaxId({})
      setCountryTaxIdDefault([])
      setAllCountries([])
      setSupportedCryptoStates([])
    }
  }

  // update the form input field
  const handleIBFormInputChange = (event) => {
    let { name, value } = event.target

    // console.log('name', name)

    if (
      name !== 'investment_plan' &&
      name !== 'employment_status' &&
      name !== 'tax_id_type' &&
      name !== 'liquidity_needs' &&
      name !== 'investment_objective' &&
      name !== 'primary_funds' &&
      name !== 'options_knowledge_level' &&
      name !== 'risk_tolerance'
    ) {
      value = value?.replace(/[^A-Za-z0-9\s]/g, '')
    }

    // console.log('name', value)

    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleIBIdentificationChange = (event) => {
    let { name, value } = event.target
    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // handle the birthday value from the date object
  const handleBirthdayInputChange = (name, value) => {
    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // parse calendar date to date format YYYY/MM/DD
  const parseCalendarDate = (e) => {
    let date = new Date(e.target.value)
    let name = e?.target?.name
    let date_obj_name = name + '_date_obj'
    let year = date.getFullYear()
    let month = String(date.getMonth() + 1).padStart(2, '0') // getMonth() returns month from 0 to 11
    let day = String(date.getDate()).padStart(2, '0')
    let formattedDate = `${year}/${month}/${day}`
    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: formattedDate,
      [date_obj_name]: date,
    }))
  }

  // fetch all the dynamic options from the api
  const fetchPresets = async () => {
    setIsLoadingData(true)
    try {
      let res = await axios.get(`${SearchEndpoint}/preset-data`)

      // once get the data, get the options from the api
      if (res?.data) {
        // let countries = []

        // let USATag = null
        // let USATag2 = null

        // res.data.countries.map((country) => {
        //   if (country.name !== 'United States') {
        //     countries.push({
        //       label: country.name,
        //       value: country.id,
        //     })
        //   } else {
        //     USATag = country
        //   }
        // })
        // countries.unshift({
        //   label: USATag.name,
        //   value: USATag.id,
        // })
        // setCountries(countries)

        // let allCountries = []
        // res.data.countries_all.map((country) => {
        //   // the api has "test1" need to exclude from the
        //   if (country?.name !== 'test1' || country?.name !== 'United States') {
        //     allCountries.push({
        //       label: country.name,
        //       value: country.id,
        //     })
        //   }

        //   if (country?.name === 'United States') {
        //     USATag2 = country
        //   }
        // })
        // allCountries.unshift({
        //   label: USATag2.name,
        //   value: USATag2.id,
        // })
        // setAllCountries(allCountries)

        // let employmentStatusOptions = []
        // res.data.emp_status.map((status) => {
        //   employmentStatusOptions.push({
        //     label: Object.values(status)[0],
        //     value: Object.keys(status)[0],
        //   })
        // })

        let employmentTypeOptions = []
        res.data.emp_types.map((type) => {
          employmentTypeOptions.push({
            label: Object.values(type)[0],
            value: Object.keys(type)[0],
          })
        })
        setEmploymentTypeOptions(employmentTypeOptions)

        let employmentPositionOptions = []
        res.data.emp_positions.map((option) => {
          employmentPositionOptions.push({
            label: props?.t(Object.keys(option)[0]?.toLowerCase()),
            value: Object.keys(option)[0],
          })
        })
        setEmploymentPositionOptions(employmentPositionOptions)

        let usaStates = []
        res.data.us_states.map((option) => {
          usaStates.push({
            label: Object.values(option)[0],
            value: Object.keys(option)[0],
          })
        })
        setAmericaStates(usaStates)
      }

      setIsLoadingData(false)
    } catch (error) {
      console.log(error)
      setIsLoadingData(false)
    }
  }

  // check if the user is at least 18 years old before create the account
  const isAtLeast18YearsOld = (year, month, day) => {
    const currentDate = new Date()
    const inputDate = new Date(year, month - 1, day) // JavaScript months are 0-based (0 = January, 1 = February, etc.)

    const ageDifferenceInMilliseconds = currentDate - inputDate
    const ageInYears =
      ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)

    return ageInYears >= 18
  }

  function validateTaxID(value) {
    // Check if the value is a string and its length is greater than 1 and less than or equal to 40
    if (value.length <= 1 || value.length > 40) {
      return false
    }

    // Check for invalid characters (only letters, digits, dashes, periods, and plus signs allowed)
    const allowedCharactersRegex = /^[a-zA-Z0-9\-+.]+$/
    if (!allowedCharactersRegex.test(value)) {
      return false
    }

    // Check if the value contains at least one digit
    const containsDigitRegex = /\d/
    if (!containsDigitRegex.test(value)) {
      return false
    }

    // Check for disallowed patterns (all same digit or sequential characters)
    const sameDigitRegex = /^(.)\1+$/ // Matches values with all the same character
    const increasingSequence = '0123456789'
    const decreasingSequence = '9876543210'

    // Normalize the value by removing non-digits for sequence checks
    const digitsOnly = value.replace(/\D/g, '')

    if (
      sameDigitRegex.test(digitsOnly) || // All same digit
      increasingSequence.includes(digitsOnly) || // Sequential increasing
      decreasingSequence.includes(digitsOnly) // Sequential decreasing
    ) {
      return false
    }

    // All checks passed
    return true
  }

  const createErrorToast = (step, error_title, error_msg) => {
    toastErrorMsg.current.show({
      severity: 'error',
      summary: `${props.t('step')} ${step} - ${error_title}`,
      detail: error_msg,
      life: 3000,
    })
  }

  // console.log('allcoutnries', allCountries)
  // console.log('countries', countries)

  // validate all the form fields
  const validateForm = () => {
    const {
      tax_id,
      tax_id_type,
      international_country_address,
      country_of_birth,
      citizenship,
      marital_status,
      ssn,
      no_dependents,
      citizenship_confirmation,
      visa_type,
      visa_expiration_date,
      visa_departure_date,
      street_1,
      city,
      state,
      postal_code,
      employment_status,
      company_name,
      employment_position,
      employer_address_street_1,
      employer_address_city,
      employer_address_state,
      employer_address_postal_code,
      employer_address_country,
      annual_net_income,
      net_worth,
      liquid_net_worth,
      primary_funds,
      knowledge_level,
      investment_objective,
      options_knowledge_level,
      affiliations_description,
      investment_plan,
      risk_tolerance,
    } = IBFormInfo

    if (!prefinraData?.payload?.email || !prefinraData?.payload?.phone) {
      createErrorToast(1, props?.t('somethingwentwrong'), props?.t(''))
    }

    const isInternationalUser = !prefinraData?.payload?.ustaxpayer

    // step 1 form validateion for empty fields
    if (
      !country_of_birth ||
      !citizenship ||
      !marital_status ||
      !no_dependents ||
      !street_1 ||
      !city ||
      !postal_code
    ) {
      createErrorToast(
        1,
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }

    // step 1a validate if internation user check if citizen confirmation is filled out
    if (!isInternationalUser) {
      if (citizenship !== 'USA') {
        if (!citizenship_confirmation) {
          createErrorToast(
            1,
            props?.t('incompleted'),
            props?.t('please_make_sure_required_fields_filled')
          )
          return false
        } else {
          if (citizenship_confirmation === 'visa') {
            if (!visa_type || !visa_expiration_date) {
              createErrorToast(
                1,
                props?.t('incompleted'),
                props?.t('please_make_sure_required_fields_filled')
              )
              return false
            } else {
              if (visa_type === 'B1' || visa_type === 'B2') {
                if (!visa_departure_date) {
                  createErrorToast(
                    1,
                    props?.t('incompleted'),
                    props?.t('please_make_sure_required_fields_filled')
                  )
                  return false
                }
              }
            }
          }
        }
      }
    } else {
      if (!tax_id_type) {
        createErrorToast(
          1,
          props?.t('incompleted'),
          props?.t('please_make_sure_required_fields_filled')
        )
        return false
      }
    }

    const identificationNum = isInternationalUser ? tax_id : ssn

    if (!identificationNum) {
      createErrorToast(
        1,
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }

    if (isInternationalUser && !validateTaxID(identificationNum)) {
      createErrorToast(
        1,
        props?.t('tax_id_error'),
        props?.t('enter_valid_tax_id')
      )
      return false
    }

    const hasState = state

    if (!hasState) {
      createErrorToast(
        1,
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }

    if (isInternationalUser) {
      if (!international_country_address) {
        createErrorToast(
          1,
          props?.t('incompleted'),
          props?.t('please_make_sure_required_fields_filled')
        )
        return false
      }
    }
    // step 2 form validation

    // if not filled
    console.log('employment_status', employment_status, company_name)
    if (!employment_status) {
      createErrorToast(
        2,
        props?.t('incompleted'),
        props?.t('choose_employment_status')
      )
      return false
    } else if (employment_status === 'EMPLOYED') {
      //it has other fields to check
      console.log('company_name', company_name)
      if (
        !company_name ||
        !employment_position ||
        !employer_address_street_1 ||
        !employer_address_city ||
        !employer_address_postal_code ||
        !employer_address_state ||
        !employer_address_country
      ) {
        createErrorToast(
          2,
          props?.t('incompleted'),
          props?.t('please_make_sure_required_fields_filled')
        )
        return false
      }
    }

    //step 3 form validation for empty fields
    if (
      !annual_net_income ||
      !net_worth ||
      !liquid_net_worth ||
      !primary_funds
    ) {
      createErrorToast(
        3,
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }
    //step 4 form validation for empty field
    if (
      !risk_tolerance ||
      !investment_plan ||
      !knowledge_level ||
      !options_knowledge_level ||
      !investment_objective
    ) {
      createErrorToast(
        4,
        props?.t('incompleted'),
        props?.t('please_make_sure_required_fields_filled')
      )
      return false
    }

    // step 5 form validation for empty fields
    if (isEligibilityWrong) {
      if (!affiliations_description) {
        createErrorToast(
          5,
          props?.t('incompleted'),
          props?.t('please_make_sure_required_fields_filled')
        )
        return false
      }
    }

    if (isInternationalUser) {
      if (!alpacaInternationalAgree1 || !alpacaInternationalAgree2) {
        createErrorToast(
          6,
          props?.t('incompleted'),
          props?.t('all_agreements_not_met')
        )
        return false
      }
    }
    if (!agreeAlpacaRead || !agreeAlpacaSign) {
      createErrorToast(
        6,
        props?.t('incompleted'),
        props?.t('all_agreements_not_met')
      )
      return false
    }
    return true
  }

  // creat the account body for the api payload for create account
  const createIBAcctBody = () => {
    function getCurrentTimestamp(pattern) {
      const date = new Date()
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'UTC',
      }
      const dateTimeFormat = new Intl.DateTimeFormat('en-GB', options)
      const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second },
      ] = dateTimeFormat.formatToParts(date)
      return `${year}-${day}-${month}T${hour}:${minute}:${second}Z`
    }

    const TIMESTAMP_PATTERN = "yyyy-MM-dd'T'HH:mm:ss'Z'"

    function formatDate(year, month, day) {
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
        2,
        '0'
      )}`
    }

    let body = {}
    let dob = formatDate(
      parseInt(prefinraData?.payload.birth_year, 10),
      parseInt(prefinraData?.payload.birth_month, 10),
      parseInt(prefinraData?.payload.birth_day, 10)
    )

    // Send crypto agreement IF Country is United States and state
    let sendCryptoAgreement =
      prefinraData?.payload?.ustaxpayer &&
      supportedCryptoStates.includes(IBFormInfo?.state)

    let agreements = [
      {
        agreement: 'account_agreement',
        signed_at: getCurrentTimestamp(TIMESTAMP_PATTERN),
        ip_address: publicIP,
      },
      {
        agreement: 'customer_agreement',
        signed_at: getCurrentTimestamp(TIMESTAMP_PATTERN),
        ip_address: publicIP,
      },
      // Uncomment the following agreement if needed
      // {
      //     agreement: "options_agreement",
      //     signed_at: getCurrentTimestamp(TIMESTAMP_PATTERN),
      //     ip_address: "185.13.21.99"
      // }
    ]

    if (sendCryptoAgreement) {
      agreements.push({
        agreement: 'crypto_agreement',
        signed_at: getCurrentTimestamp(TIMESTAMP_PATTERN),
        ip_address: publicIP,
      })
    }

    let documents = []

    // If not us tax payer add this document to the thing
    if (!prefinraData?.payload?.ustaxpayer) {
      documents?.push({
        document_type: 'w8ben',
        content_data: {
          country_citizen: IBFormInfo?.citizenship,
          date: new Date().toISOString().split('T')[0], // Format as "yyyy-MM-dd"
          date_of_birth: dob,
          full_name: `${prefinraData?.payload.first_name} ${prefinraData?.payload.last_name}`,
          ip_address: publicIP,
          tax_id_ssn: IBFormInfo?.tax_id,
          permanent_address_city_state: `${IBFormInfo.city}, ${IBFormInfo.state}`,
          permanent_address_country: IBFormInfo?.international_country_address,
          permanent_address_street: `${IBFormInfo.street_1} ${IBFormInfo.street_2}`,
          revision: '10-2021',
          timestamp: getCurrentTimestamp(TIMESTAMP_PATTERN),
          signer_full_name: `${prefinraData?.payload.first_name} ${prefinraData?.payload.last_name}`,
        },
      })
    }

    console.log(agreements)

    const employment_address = [
      IBFormInfo?.employer_address_street_1,
      IBFormInfo?.employer_address_street_2,
      IBFormInfo?.employer_address_city,
      IBFormInfo?.employer_address_state,
      IBFormInfo?.employer_address_postal_code,
      IBFormInfo?.employer_address_country,
    ]

    body = {
      investment_objective: IBFormInfo?.investment_objective,
      risk_tolerance: IBFormInfo?.risk_tolerance,
      investment_experience_with_options: IBFormInfo?.options_knowledge_level,
      investment_experience_with_stocks: IBFormInfo?.knowledge_level,
      annual_income: IBFormInfo?.annual_net_income,
      net_worth: IBFormInfo?.net_worth,
      liquid_net_worth: IBFormInfo?.liquid_net_worth,
      liquidity_needs: IBFormInfo?.liquidity_needs,
      marital_status: IBFormInfo?.marital_status,
      number_of_dependents: IBFormInfo?.no_dependents,
      investment_time_horizon: IBFormInfo?.investment_plan,
      contact: {
        email_address: prefinraData?.payload?.email,
        phone_number: '+' + prefinraData?.payload?.phone,
        street_address: [IBFormInfo?.street_1],
        unit: IBFormInfo?.street_2 ?? '',
        city: IBFormInfo?.city,
        state: IBFormInfo?.state,
        postal_code: IBFormInfo?.postal_code,
      },
      identity: {
        tax_id_type: prefinraData?.payload?.ustaxpayer
          ? 'USA_SSN'
          : IBFormInfo?.tax_id_type,
        given_name: prefinraData?.payload?.first_name,
        family_name: prefinraData?.payload?.last_name,
        date_of_birth: dob,
        tax_id: prefinraData?.payload?.ustaxpayer
          ? IBFormInfo?.ssn
          : IBFormInfo?.tax_id,
        country_of_citizenship: IBFormInfo?.citizenship,
        country_of_birth: IBFormInfo?.country_of_birth,
        country_of_tax_residence: IBFormInfo?.international_country_address,
        funding_source: [IBFormInfo?.primary_funds],
      },
      disclosures: {
        is_control_person: false,
        is_affiliated_exchange_or_finra: false,
        is_politically_exposed: false,
        immediate_family_exposed: false,
        employment_status: IBFormInfo?.employment_status,
        employer_name: IBFormInfo?.company_name,
        // employer_address: [
        //   IBFormInfo?.employer_address_street_1, // Address 1
        //   IBFormInfo?.employer_address_street_2, // Apt, Suite, Bldg #
        //   prefinraData?.payload?.ustaxpayer
        //     ? IBFormInfo?.employer_address_city // U.S. City
        //     : IBFormInfo?.employer_address_city +
        //       ' , ' +
        //       IBFormInfo?.employer_address_state, // International City/Region
        //   prefinraData?.payload?.ustaxpayer
        //     ? IBFormInfo?.employer_address_state // U.S. State
        //     : IBFormInfo?.international_employer_region, // International Region
        //   IBFormInfo?.employer_address_postal_code, // Zip/Postal Code
        //   IBFormInfo?.employer_address_country, // Country
        //   !prefinraData?.payload?.ustaxpayer
        //     ? IBFormInfo?.international_employer_country
        //     : '', // International Country
        // ]
        employer_address: employment_address.filter(Boolean).join(' '), // Join with commas or another separator
        employment_position: IBFormInfo?.employment_position,
      },
      agreements: agreements,
      documents: documents,
    }

    return body
  }

  const fetchProperInvestmentName = (name) => {
    const list = [
      {
        label: 'Growth + Trading Profits + Speculation + Hedging',
        value: ['Growth', 'Trading', 'Speculation', 'Hedging'],
      },
      {
        label: 'Growth + Speculation + Hedging',
        value: ['Growth', 'Speculation', 'Hedging'],
      },
      {
        label: 'Speculation + Hedging + Trading Profits',
        value: ['Speculation', 'Hedging', 'Trading'],
      },
      {
        label: 'Growth + Trading Profits',
        value: ['Growth', 'Trading'],
      },
      {
        label: 'Hedging + Trading Profits',
        value: ['Hedging', 'Trading'],
      },
      {
        label: 'Speculation + Hedging',
        value: ['Speculation', 'Hedging'],
      },
      {
        label: 'Growth + Speculation',
        value: ['Growth', 'Speculation'],
      },
    ]

    const matchedItem = list?.find((item) => item?.label === name)
    return matchedItem ? matchedItem.value : []
  }

  const createAccount = async (body) => {
    // call the api to submit the form
    try {
      let res = await axios.post(
        `${BaseURL}/mobile/alpaca/account/create`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      // let res2 = await axios.get(`${BaseURL}/mobile/account-created`, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //   },
      // })

      // let res3 = await axios.get(
      //   `${BaseURL}/mobile/update/account-creation-request`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //     },
      //   }
      // )

      console.log(res)
      if (res?.data?.output?.status === 'failed') {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Submit form failed',
          detail: 'Something went wrong, please try again later',
          life: 3000,
        })
        return false
      }
      // console.log(res2)
      // console.log(res3)
      return true
    } catch (e) {
      console.log(e)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Submit form failed',
        detail: 'Something went wrong, please try again later',
        life: 3000,
      })
    }
    return false
  }

  const submitFinra = async () => {
    let finraForm = {
      subPackage: 'basic',
      birthdayDay: 0,
      birthdayMonth: 0,
      birthdayYear: 0,
    }

    if (IBFormInfo?.investment_plan === '1-5') {
      finraForm.investmentMainReason = 'SHORT_TERM'
    } else if (IBFormInfo?.investment_plan === '5-10') {
      finraForm.investmentMainReason = 'MEDIUM_TERM'
    } else {
      finraForm.investmentMainReason = 'LONG_TERM'
    }

    if (IBFormInfo?.investment_plan === '1-5') {
      finraForm.investmentPeriod = 5
    } else if (IBFormInfo?.investment_plan === '5-10') {
      finraForm.investmentPeriod = 10
    } else if (IBFormInfo?.investment_plan === '10-20') {
      finraForm.investmentPeriod = 20
    } else if (IBFormInfo?.investment_plan === '20-30') {
      finraForm.investmentPeriod = 30
    } else {
      finraForm.investmentPeriod = 40
    }

    if (
      IBFormInfo?.risk_tolerance === 'very aggressive' ||
      IBFormInfo?.risk_tolerance === 'aggressive'
    ) {
      finraForm.riskTolerance = 'GAINS'
    } else {
      finraForm.riskTolerance = IBFormInfo?.risk_tolerance
    }

    if (IBFormInfo?.knowledge_level === 'None') {
      finraForm.investmentExperience = 'NONE'
    } else if (IBFormInfo?.knowledge_level === 'Limited') {
      finraForm.investmentExperience = 'SOME'
    } else {
      finraForm.investmentExperience = 'CONSIDERABLE'
    }

    let trade = Number(IBFormInfo?.trades_per_year)

    if (trade < 20) {
      finraForm.investmentObjectives = 'NEW'
    } else if (trade < 80) {
      finraForm.investmentObjectives = 'INFREQUENT'
    } else if (trade < 300) {
      finraForm.investmentObjectives = 'FREQUENT'
    } else if (trade < 500) {
      finraForm.investmentObjectives = 'ACTIVE_DAILY'
    } else {
      finraForm.investmentObjectives = 'LONG_TERM'
    }

    try {
      let res = await axios.post(`${BaseURL}/mobile/dw/quiz`, finraForm, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      if (res?.data?.success) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Submit Finra Questions failed',
        detail: 'Something went wrong, please try again later',
        life: 3000,
      })
    }
    return false
  }

  const formatDateObj = (date) => {
    const originalDate = new Date(date)

    const year = originalDate.getFullYear()
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
    const day = originalDate.getDate().toString().padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const submitForm = async () => {
    // validat the form

    // if all form field are valid
    if (validateForm()) {
      // structure the body

      let body = createIBAcctBody()
      // toastErrorMsg.current.show({
      //   severity: 'info',
      //   summary: 'DEBUG: CREATE ACCOUNT BODY',
      //   detail: 'Passed validation, double check create account body',
      //   life: 3000,
      // })
      console.log('create account body', body)
      // return false
      console.log('documentNumber', documentNumber)
      // if ((await submitFinra()) && (await submitDocuments())) {
      if (await submitFinra()) {
        if (await createAccount(body)) {
          console.log('after create-user api if statment')
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      console.log('failed validate form')
    }

    return false
  }

  const submitDocuments = async () => {
    //  setLoading(true)

    console.log('inside submitDocuments')
    const formData = new FormData()

    const metadataObj = {}

    // formData.append('metadata', JSON.stringify(metadataObj))

    for (const [key, value] of Object.entries(selectedOptionsDoc)) {
      // if it is image, then do the image extension else if pdf do pdf extension
      if (value?.type?.includes('image')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.jpeg`)
      } else if (value?.type?.includes('pdf')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.pdf`)
      }

      // if you can find it is existed then it has options dropdown
      if (selectedOptions?.[key]) {
        metadataObj[key || ''] = selectedOptions?.[key]

        // if it is passport and driver license then add the expiration date

        if (key === 'Proof of identity and date of birth') {
          if (selectedOptions?.[key] === 'Driver License') {
            console.log('inside driver lience pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(documentsExpiration) ?? ''
          } else if (selectedOptions?.[key] === 'Passport') {
            console.log('inside passport pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(documentsExpiration) ?? ''
          }
        } else if (key === 'Proof of address') {
          if (selectedOptions?.[key] === 'Driver License') {
            console.log('inside driver lience pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(addressDocExpiration) ?? ''
          } else if (selectedOptions?.[key] === 'Passport') {
            console.log('inside passport pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(addressDocExpiration) ?? ''
          }
        }
      } else {
        metadataObj[key || ''] = ''
      }
    }
    formData.append('metadata', JSON.stringify(metadataObj))

    // console.log('JSON.stringify(metadataObj)', JSON.stringify(metadataObj))

    // //todo-need to comment out
    // for (let entry of formData.entries()) {
    //   console.log('entry', entry)
    // }
    // return

    try {
      // let res = await axios.post(`${BaseURL}/ibkr/upload-test`, formData, {
      //   headers: {
      //     Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //   },
      // })

      let res = await axios.post(`${BaseURL}/ibkr/save-file-as-hex`, formData, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      if (res?.data.success) {
        console.log('good to go')
        //  setLoading(false)
        //  await props?.getIBStatus()
        //  localStorage.setItem('isIBUserKYC', 'true')
        //  localStorage.setItem('userId', props?.getEmailObj?.userID)

        //  props?.toggleUploaded()
        //  props?.closeModal()

        return true
      } else {
        console.log('failed')
        //  setLoading(false)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Upload Documents failed',
          detail: 'Something wrong with data uploaded. Please try again',
          life: 3000,
        })
        return false
      }
    } catch (error) {
      console.log(error)
      //  setLoading(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Upload Documents failed',
        detail: 'Please upload the files and try again',
        life: 3000,
      })
    }

    return false
  }

  // get the dynamic url link for the IB agreenment based on the if option or bond option is selected or not
  const getCorrectIBAgreement = () => {
    let url =
      'https://www.investall.app/disclosures-ib?disclosures=STK,BOND,OPT'

    if (bondsToggleStatus === 'Yes' && optionsToggleStatus === 'Yes') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,BOND,OPT'
    } else if (bondsToggleStatus === 'Yes' && optionsToggleStatus === 'No') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,BOND'
    } else if (bondsToggleStatus === 'No' && optionsToggleStatus === 'Yes') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,OPT'
    } else {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK'
    }

    return url
  }

  // handle for the investment options for multiple chekcbox or single checkbox
  const handleInvestmentObjChange = (e) => {
    let investmentObj = []
    if (IBFormInfo?.investment_objectives) {
      investmentObj = [...IBFormInfo?.investment_objectives]
    }

    // if the item is checked
    if (e.checked) {
      // if it is option and bond toggled, then it only allow select one checkbox
      if (optionsToggleStatus === 'Yes' || bondsToggleStatus === 'Yes') {
        if (IBFormInfo?.investment_objectives.length < 1) {
          investmentObj.push(e.value)
        }
      } else {
        // if not option and bond toggled, then select many as user want
        investmentObj.push(e.value)
      }
    } else {
      // if it is unchecked, then filter out the unchecked, only keep the checked item
      investmentObj = investmentObj.filter((category) => category !== e.value)
    }

    setIBFormInfo((prevData) => ({
      ...prevData,
      ['investment_objectives']: investmentObj,
    }))
  }

  // handle source of wealth option change
  const handleIncomeChange = (index, value) => {
    const updatedSources = [...incomeSources]

    // console.log('change')
    // console.log(updatedSources[index].source_type !== 'SOW-IND-Other')
    // console.log(updatedSources[index].hasOwnProperty('description'))

    // if the value is not sow-ind-other and has description then delete that
    if (
      value !== 'SOW-IND-Other' &&
      updatedSources[index].hasOwnProperty('description')
    ) {
      delete updatedSources[index].description
    }

    // console.log(updatedSources[index])

    updatedSources[index] = { ...updatedSources[index], source_type: value }
    setIncomeSources(updatedSources)
  }
  // handle source of wealth percentage change for each source option
  const handlePercentageChange = (index, value) => {
    const updatedSources = [...incomeSources]
    updatedSources[index] = {
      ...updatedSources[index],
      percentage: value?.toString(),
    }
    setIncomeSources(updatedSources)
  }

  // handle source of wealth percentage change for each source option
  const handleDescriptionChange = (index, value) => {
    const updatedSources = [...incomeSources]
    updatedSources[index] = {
      ...updatedSources[index],
      description: value,
    }
    setIncomeSources(updatedSources)
  }

  // add new source content
  const addNewIncomeSource = () => {
    setIncomeSources([
      ...incomeSources,
      { source_type: '', percentage: undefined, is_used_for_funds: true },
    ])
  }

  // handle delete the source
  const handleDelete = (index) => {
    const updatedSources = [...incomeSources]
    updatedSources.splice(index, 1)
    setIncomeSources(updatedSources)
  }

  // check if all the data is filled
  const isAllDocUploaded = () => {
    // get total size for api and the length that user filled
    let totalSize = optionsDetail?.payload?.length
    let totalFilled = Object?.values(selectedOptionsDoc)?.filter(
      (value) => value !== null && value !== ''
    )?.length

    // get the dropdown length from api and user entered
    let totalDropdownCount = optionsDetail?.payload?.filter(
      (item) => item.has_options === true
    )?.length
    let totalDropdownFilled = Object?.keys(selectedOptions)?.length

    if (
      totalSize !== totalFilled ||
      totalDropdownCount !== totalDropdownFilled
    ) {
      return false
    }
    return true
  }

  // personal information form compoennt
  const PersonalForm = () => {
    // month template for the prime react ui calander
    const monthNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          style={{ lineHeight: 1 }}
        />
      )
    }

    // year template for the prime react ui calander
    const yearNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          className='p-ml-2'
          style={{ lineHeight: 1 }}
        />
      )
    }

    // parse the birthday from 01/01/2023, to three variables with date, year, month separately
    const parseBirthday = (birthday) => {
      const date = new Date(birthday)
      handleBirthdayInputChange('birthday', birthday)
      handleBirthdayInputChange('birthdayDay', date.getDate())
      handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
      handleBirthdayInputChange('birthdayYear', date.getFullYear())
    }

    return (
      <>
        <Panel header={props?.t('contactaddress')} toggleable>
          <div className='input-container'>
            <label>{props?.t('address1')}</label>
            <InputText
              placeholder={props?.t('address1')}
              name='street_1'
              value={IBFormInfo?.street_1}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('aptsuitebldg')}</label>
            <InputText
              placeholder={props?.t('aptsuitebldg')}
              name='street_2'
              value={IBFormInfo?.street_2}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <label>
              {prefinraData?.payload?.ustaxpayer
                ? props?.t('city')
                : props?.t('citytown')}
            </label>
            <InputText
              placeholder={
                prefinraData?.payload?.ustaxpayer
                  ? props?.t('city')
                  : props?.t('citytown')
              }
              name='city'
              value={IBFormInfo?.city}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            {IBFormInfo?.international_country_address === 'USA' ? (
              <>
                <label>{props?.t('state')}</label>
                <Dropdown
                  value={IBFormInfo?.state}
                  name='state'
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('state')}
                  options={americaStates}
                />
              </>
            ) : (
              <>
                <label>{props?.t('stateprovinceregion')}</label>
                <InputText
                  placeholder={props?.t('stateprovinceregion')}
                  name='state'
                  value={IBFormInfo?.state}
                  onChange={handleIBFormInputChange}
                />
              </>
            )}
          </div>
          <div className='input-container'>
            <label>
              {prefinraData?.payload?.ustaxpayer
                ? props?.t('zipcode')
                : props?.t('zippostalcode')}
            </label>
            <InputText
              keyfilter='pint'
              placeholder={
                prefinraData?.payload?.ustaxpayer
                  ? props?.t('zipcode')
                  : props?.t('zippostalcode')
              }
              name='postal_code'
              value={IBFormInfo?.postal_code}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('country')}</label>
            <Dropdown
              value={IBFormInfo?.international_country_address}
              name='international_country_address'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('country')}
              options={allCountries}
            />
          </div>
          {/* <div className=''>
            <div
              className='checkbox-container'
              style={isSameAddress ? {} : { paddingBottom: '1rem' }}
            >
              <Checkbox
                onChange={() => setIsSameAddress(!isSameAddress)}
                checked={isSameAddress}
              />
              <label>
                My mailing address is the same as the address of residence
              </label>
            </div>

            {!isSameAddress && (
              <>
                <div className='input-container'>
                  <InputText
                    placeholder='Mailing Address 1'
                    name='mailing_street_1'
                    value={IBFormInfo?.mailing_street_1}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    placeholder='Apt, Suite, Bldg #'
                    name='mailing_street_2'
                    value={IBFormInfo?.mailing_street_2}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    placeholder='Mailing City'
                    name='mailing_city'
                    value={IBFormInfo?.mailing_city}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <Dropdown
                    value={IBFormInfo?.mailing_state}
                    name='mailing_state'
                    onChange={handleIBFormInputChange}
                    placeholder='Mailing State'
                    options={americaStates}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    keyfilter='pint'
                    placeholder='Mailing Zip Code'
                    name='mailing_postal_code'
                    value={IBFormInfo?.mailing_postal_code}
                    onChange={handleIBFormInputChange}
                  />
                </div>
              </>
            )}
          </div> */}
        </Panel>
        <Panel header={props?.t('personalinformation')} toggleable>
          <div className='input-container'>
            <label htmlFor='citizenship'>{props?.t('citizenship')}</label>

            <Dropdown
              value={IBFormInfo?.citizenship}
              name='citizenship'
              onChange={handleIBFormInputChange}
              options={allCountries}
              placeholder={props?.t('citizenship')}
            />
          </div>

          <div className='input-container'>
            <label>{props?.t('countryofbirth')}</label>
            <Dropdown
              value={IBFormInfo?.country_of_birth}
              name='country_of_birth'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('countryofbirth')}
              options={allCountries}
            />
          </div>

          {/* if citizenship is international */}
          {(prefinraData?.payload?.ustaxpayer
            ? IBFormInfo?.citizenship && IBFormInfo?.citizenship !== 'USA'
            : false) && (
            <>
              <div className='input-container'>
                <label htmlFor='citizenship'>
                  {props?.t('confirmresidencestatus')}
                </label>
                <Dropdown
                  value={IBFormInfo?.citizenship_confirmation}
                  name='citizenship_confirmation'
                  onChange={handleIBFormInputChange}
                  options={[
                    {
                      label: 'Green Card',
                      value: 'greencard',
                    },
                    {
                      label: 'Visa',
                      value: 'visa',
                    },
                  ]}
                  placeholder='Select an Option'
                />
              </div>
              {IBFormInfo?.citizenship_confirmation === 'visa' && (
                <>
                  <div className='input-container'>
                    <label htmlFor='visa_type'>{props?.t('visatype')}</label>
                    <Dropdown
                      value={IBFormInfo?.visa_type}
                      name='visa_type'
                      onChange={handleIBFormInputChange}
                      options={alpacaVisaTypes}
                      placeholder={props?.t('selectvisatype')}
                    />
                  </div>
                  <div className='input-container'>
                    <label htmlFor='visa_expiration_date'>
                      {props?.t('visaexpirationdate')}
                    </label>
                    <Calendar
                      value={IBFormInfo?.visa_expiration_date_date_obj}
                      onChange={(e) => parseCalendarDate(e)}
                      dateFormat='yy/mm/dd'
                      name='visa_expiration_date'
                      showIcon
                      icon='pi pi-calendar'
                      placeholder='Visa Expiration Date'
                      monthNavigator={true}
                      yearNavigator={true}
                      monthNavigatorTemplate={monthNavigatorTemplate}
                      yearNavigatorTemplate={yearNavigatorTemplate}
                      yearRange={`1900:${new Date().getFullYear()}`}
                      className='calendar-input'
                    ></Calendar>
                  </div>
                  {(IBFormInfo?.visa_type === 'B1' ||
                    IBFormInfo?.visa_type === 'B2') && (
                    <div className='input-container'>
                      <label htmlFor='visa_departure_date'>
                        {props?.t('dateofdeparturefromusa')}
                      </label>
                      <Calendar
                        value={IBFormInfo?.visa_departure_date_date_obj}
                        onChange={(e) => parseCalendarDate(e)}
                        dateFormat='yy/mm/dd'
                        name='visa_departure_date'
                        showIcon
                        icon='pi pi-calendar'
                        placeholder={props?.t('dateofdeparturefromusa')}
                        monthNavigator={true}
                        yearNavigator={true}
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        yearRange={`1900:${new Date().getFullYear()}`}
                        className='calendar-input'
                      ></Calendar>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className='input-container'>
            {prefinraData?.payload?.ustaxpayer ? (
              <>
                <label>{props?.t('socialsecuritynumber')}</label>
                <InputMask
                  mask='999-99-9999'
                  value={IBFormInfo?.ssn}
                  name='ssn'
                  onChange={handleIBIdentificationChange}
                  placeholder='SSN'
                />
              </>
            ) : (
              // international user
              <>
                <div className='input-container'>
                  <label>{props?.t('taxidentification')}</label>
                  <Dropdown
                    value={IBFormInfo?.tax_id_type}
                    name='tax_id_type'
                    onChange={handleIBFormInputChange}
                    placeholder={props?.t('selectformoftaxidentification')}
                    options={
                      countryTaxId?.[
                        IBFormInfo?.international_country_address
                      ] ?? countryTaxIdDefault
                    }
                  />
                </div>
                {IBFormInfo?.tax_id_type && (
                  <div className='input-container'>
                    <label>{props?.t('taxidentificationnumber')}</label>
                    <InputText
                      id='tax_id'
                      name='tax_id'
                      value={IBFormInfo?.tax_id}
                      onChange={handleIBFormInputChange}
                      placeholder={props?.t('taxidentificationnumber')}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className='input-container'>
            <label>{props?.t('maritalstatus')}</label>

            <Dropdown
              value={IBFormInfo?.marital_status}
              name='marital_status'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('maritalstatus')}
              options={maritalStatusOptions}
            />
          </div>

          <div className='input-container'>
            <label>{props?.t('numberofdependents')}</label>
            <Dropdown
              value={IBFormInfo?.no_dependents}
              name='no_dependents'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('numberofdependents')}
              options={numDependents}
            />
          </div>
        </Panel>
      </>
    )
  }

  // employement information form compoennt
  const EmploymentForm = () => {
    return (
      <>
        <Panel header={props?.t('employmentinformation')} toggleable>
          <div className='input-container'>
            <label htmlFor='employment_status'>
              {props?.t('employmentstatus')}
            </label>

            <Dropdown
              value={IBFormInfo?.employment_status}
              name='employment_status'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('employmentstatus')}
              options={employmentStatusOptions}
            />
          </div>

          {(IBFormInfo?.employment_status === 'EMPLOYED' ||
            IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
            <>
              <div className='input-container'>
                <label htmlFor='company_name'>{props?.t('employername')}</label>
                <InputText
                  id='company_name'
                  name='company_name'
                  value={IBFormInfo?.company_name}
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('employername')}
                />
              </div>
              <div className='input-container'>
                <label htmlFor='employment_position'>
                  {props?.t('employmentposition')}
                </label>
                <Dropdown
                  value={IBFormInfo?.employment_position}
                  name='employment_position'
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('employmentposition')}
                  options={employmentPositionOptions}
                />
              </div>
            </>
          )}
        </Panel>

        {(IBFormInfo?.employment_status === 'EMPLOYED' ||
          IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
          <>
            <Panel header={props?.t('employeraddress')} toggleable>
              {/* <div className='input-container'>
                <InputText
                  placeholder='Employer Address'
                  name='employer_address_full'
                  value={IBFormInfo?.employer_address_full}
                  onChange={handleIBFormInputChange}
                />
              </div> */}
              <div className='input-container'>
                <label>{props?.t('address1')}</label>
                <InputText
                  placeholder={props?.t('address1')}
                  name='employer_address_street_1'
                  value={IBFormInfo?.employer_address_street_1}
                  onChange={handleIBFormInputChange}
                />
              </div>
              <div className='input-container'>
                <label>{props?.t('aptsuitebldg')}</label>
                <InputText
                  placeholder={props?.t('aptsuitebldg')}
                  name='employer_address_street_2'
                  value={IBFormInfo?.employer_address_street_2}
                  onChange={handleIBFormInputChange}
                />
              </div>

              <div className='input-container'>
                <label>
                  {prefinraData?.payload?.ustaxpayer
                    ? props?.t('city')
                    : props?.t('citytown')}
                </label>
                <InputText
                  placeholder={
                    prefinraData?.payload?.ustaxpayer
                      ? props?.t('city')
                      : props?.t('citytown')
                  }
                  name='employer_address_city'
                  value={IBFormInfo?.employer_address_city}
                  onChange={handleIBFormInputChange}
                />
              </div>
              <div className='input-container'>
                {IBFormInfo?.employer_address_country === 'USA' ? (
                  <>
                    <label>{props?.t('state')}</label>
                    <Dropdown
                      value={IBFormInfo?.employer_address_state}
                      name='employer_address_state'
                      onChange={handleIBFormInputChange}
                      placeholder={props?.t('state')}
                      options={americaStates}
                    />
                  </>
                ) : (
                  <>
                    <label>{props?.t('stateprovinceregion')}</label>
                    <InputText
                      placeholder={props?.t('stateprovinceregion')}
                      name='employer_address_state'
                      value={IBFormInfo?.employer_address_state}
                      onChange={handleIBFormInputChange}
                    />
                  </>
                )}
              </div>
              <div className='input-container'>
                <label>
                  {prefinraData?.payload?.ustaxpayer
                    ? props?.t('zipcode')
                    : props?.t('zippostalcode')}
                </label>
                <InputText
                  keyfilter='pint'
                  placeholder={
                    prefinraData?.payload?.ustaxpayer
                      ? props?.t('zipcode')
                      : props?.t('zippostalcode')
                  }
                  name='employer_address_postal_code'
                  value={IBFormInfo?.employer_address_postal_code}
                  onChange={handleIBFormInputChange}
                />
              </div>
              <div className='input-container'>
                <label>{props?.t('country')}</label>
                <Dropdown
                  value={IBFormInfo?.employer_address_country}
                  name='employer_address_country'
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('country')}
                  options={allCountries}
                />
              </div>
              {/* {!prefinraData?.payload?.ustaxpayer ? (
                <div className='input-container'>
                  <label>{props?.t('country')}</label>
                  <Dropdown
                    value={IBFormInfo?.international_employer_country}
                    name='international_employer_country'
                    onChange={handleIBFormInputChange}
                    placeholder={props?.t('country')}
                    options={allCountries}
                  />
                </div>
              ) : (
                <div className='input-container'>
                  <label>{props?.t('country')}</label>
                  <Dropdown
                    value={IBFormInfo?.employer_address_country}
                    name='employer_address_country'
                    onChange={handleIBFormInputChange}
                    placeholder={props?.t('country')}
                    options={allCountries}
                  />
                </div>
              )} */}
            </Panel>
          </>
        )}
      </>
    )
  }

  //financial information form compoennt
  const FinancialForm = () => {
    return (
      <>
        <Panel header={props?.t('incomeinformation')} toggleable>
          <div className='input-container'>
            <label>{props?.t('annualnetincome')} ($USD)</label>
            <Dropdown
              value={IBFormInfo?.annual_net_income}
              name='annual_net_income'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('annualnetincome')}
              options={FinancialAnnualList}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('networth')} ($USD)</label>
            <Dropdown
              value={IBFormInfo?.net_worth}
              name='net_worth'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('networth')}
              options={FinancialNetWorthList}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('liquidnetworth')} ($USD)</label>
            <Dropdown
              value={IBFormInfo?.liquid_net_worth}
              name='liquid_net_worth'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('liquidnetworth')}
              options={FinancialAnnualList}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('describeyourliquidityneeds')}</label>
            <Dropdown
              value={IBFormInfo?.liquidity_needs}
              name='liquidity_needs'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('liquidityneeds')}
              options={liquidityNeeds}
            />
          </div>
          <div className='input-container'>
            <label>{props?.t('primarysourceoffundsforinvesting')}</label>
            <Dropdown
              value={IBFormInfo?.primary_funds}
              name={`primary_funds`}
              onChange={handleIBFormInputChange}
              placeholder={props?.t('source')}
              options={sourceOfWealthOptions}
            />
          </div>
        </Panel>
      </>
    )
  }

  //investment form component
  const InvestmentForm = () => {
    return (
      <>
        <Panel header={props?.t('investmentexperience')} toggleable>
          <div className='input-container'>
            <label>{props?.t('howlongdoyouplantoinvest')}</label>

            <Dropdown
              value={IBFormInfo?.investment_plan}
              name='investment_plan'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('investmentplan')}
              options={PlanInvestmentList}
            />
          </div>

          <div className='input-container'>
            <label>{props?.t('risktolerance')}</label>

            <Dropdown
              value={IBFormInfo?.risk_tolerance}
              name='risk_tolerance'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('risktolerance')}
              options={RiskToleranceList}
            />
          </div>
        </Panel>

        <Panel header={props?.t('investmenttrading')} toggleable>
          <div className='investment-single-group'>
            <label>{props?.t('stock')}</label>

            <div className='investment-inputs-container'>
              <div className='input-container'>
                <label>{props?.t('knowledgelevel')}</label>
                <Dropdown
                  value={IBFormInfo?.knowledge_level}
                  name='knowledge_level'
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('knowledgelevel')}
                  options={knowledgeOptions}
                />
              </div>
            </div>
          </div>
          <div className='investment-single-group'>
            <label>{props?.t('options')}</label>
            <div className='investment-inputs-container'>
              <label>{props?.t('knowledgelevel')}</label>
              <div className='input-container'>
                <Dropdown
                  value={IBFormInfo?.options_knowledge_level}
                  name='options_knowledge_level'
                  onChange={handleIBFormInputChange}
                  placeholder={props?.t('knowledgelevel')}
                  options={knowledgeOptions}
                />
              </div>
            </div>
          </div>
        </Panel>

        <Panel header={props?.t('investmentobjective')} toggleable>
          <div className='input-container'>
            <label>{props?.t('whatisyourinvestmentobjective')}</label>
            <Dropdown
              value={IBFormInfo?.investment_objective}
              name='investment_objective'
              onChange={handleIBFormInputChange}
              placeholder={props?.t('investmentobjective')}
              options={InvestmentOptionObjList}
            />
          </div>
        </Panel>
      </>
    )
  }

  // agreement and yes/no questions for form components
  const AcknowledgmentForm = () => {
    return (
      <>
        <Panel header={props?.t('eligibilityconfirmation')} toggleable>
          <div className='multiple-radio-buttons'>
            <div>
              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_1'
                  name='regulatory_question_1'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />
                <label htmlFor='regulatory_question_1' className='ml-2'>
                  {props?.t('notemployeeofpubliclytradedcompany')}
                </label>
              </div>
            </div>

            <div>
              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_2'
                  name='regulatory_question_2'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />

                <label htmlFor='regulatory_question_2' className='ml-2'>
                  {props?.t('dontcontrolpubliclytradedcompany')}
                </label>
              </div>
            </div>

            <div>
              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_3'
                  name='regulatory_question_3'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />
                <label htmlFor='regulatory_question_3' className='ml-2'>
                  {props?.t('nofamilymemberorregisteredbroker')}
                </label>
              </div>
            </div>
          </div>
        </Panel>

        <div
          className='checkbox-container'
          style={!isEligibilityWrong ? {} : { paddingBottom: '1rem' }}
        >
          <Checkbox
            onChange={() => setIsEligibilityWrong(!isEligibilityWrong)}
            checked={isEligibilityWrong}
          />
          <label>{props?.t('nosomethingswrong')}</label>
        </div>

        {isEligibilityWrong && (
          <Panel header={props?.t('affiliationsdisclosure')} toggleable>
            <label htmlFor=''>
              {props?.t('includedetaileddescriptionofaffiliation')}
            </label>
            <div className='input-container'>
              <InputTextarea
                value={IBFormInfo?.affiliations_description}
                placeholder='Enter your description'
                onChange={handleIBFormInputChange}
                name='affiliations_description'
                style={{ height: '90px' }}
                // rows={5}
                // cols={10}
              />
            </div>
          </Panel>
        )}

        {/* <Panel header='Agreement' toggleable>
          <div className='multiple-checkbox'>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeIBPaperwork'
                value={agreeIBPaperwork}
                onChange={() => setAgreeIBPaperwork(!agreeIBPaperwork)}
                checked={agreeIBPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href={getCorrectIBAgreement()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Interactive Brokers LLC Account Agreements
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeForescitePaperwork'
                value={agreeForescitePaperwork}
                onChange={() =>
                  setAgreeForescitePaperwork(!agreeForescitePaperwork)
                }
                checked={agreeForescitePaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href='https://www.forescite.ai/mobile-disclosures-forescite'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Investall Advisory Agreement
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeTaxFormPaperwork'
                value={agreeTaxFormPaperwork}
                onChange={() =>
                  setAgreeTaxFormPaperwork(!agreeTaxFormPaperwork)
                }
                checked={agreeTaxFormPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                <a
                  href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5002.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  Tax Form
                </a>
              </label>
            </div>
          </div>
          <br />

          <div className='input-container'>
            <label>
              I have read, understood and accepted the terms and conditions
              provided during account opening. I will comply to all the terms of
              agreements.
            </label>
            {/* <InputText
              placeholder='Signed your full name here'
              name='signedFullName'
              value={IBFormInfo?.signedFullName}
              onChange={handleIBFormInputChange}
            /> */}
        {/* </div> */}
        {/* </Panel> */}
      </>
    )
  }

  //upload documents forms
  const UploadDocumentsForm = () => {
    // document layout template for the uploaded
    const itemListTemplate = (docType) => (file, props) => {
      return (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={file.name}
              role='presentation'
              src={file.type === 'application/pdf' ? PDFImg : file.objectURL}
              width={file.type === 'application/pdf' ? 60 : 100}
            />
            <span>{file.name}</span>
          </div>
          <Tag
            value={props.formatSize}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      )
    }

    // empty template when no doc is uploaded yet
    const emptyTemplate = (docType) => {
      return selectedOptionsDoc?.[docType] ? (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={selectedOptionsDoc?.[docType]?.name}
              role='presentation'
              src={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? PDFImg
                  : selectedOptionsDoc?.[docType]?.objectURL
              }
              width={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? 60
                  : 100
              }
            />
            <span>{selectedOptionsDoc?.[docType]?.name}</span>
          </div>
          <Tag
            value={selectedOptionsDoc?.[docType]?.size}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              // props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      ) : (
        <div className='empty-upload-container'>
          <i className='pi pi-image'></i>
          <span className='my-5'>Please upload your document</span>
        </div>
      )
    }

    // check if the doc is more than one or not, if it is 1, then update the list, if not popup the latest one and show error message
    const uploadDocsCheck =
      (docType) =>
      async ({ files }) => {
        if (files) {
          if (files?.length > 1) {
            files.pop()
            toastErrorMsg.current.show({
              severity: 'error',
              summary: 'Upload Documents failed',
              detail: 'Please remove old files and upload new one',
              life: 3000,
            })
          } else {
            setSelectedOptionsDoc({
              ...selectedOptionsDoc,
              [docType]: files[0],
            })
          }
        }
      }

    // update the option value based on the doc_type option
    const handleOptionChange = (docType, selectedOption) => {
      setSelectedOptions({
        ...selectedOptions,
        [docType]: selectedOption,
      })

      // only change when the option title is the following
      if (docType?.toLowerCase() === 'proof of identity and date of birth') {
        setDocumentsExpiration('')
        setDocumentNumber('')
        setDocumentType(selectedOption)
      } else if (docType?.toLowerCase() === 'proof of address') {
        setAddressDocExpiration('')
        // setDocumentType(selectedOption)
      }
    }

    // make single array of string into object
    const transformedOptionObj = (options) => {
      let data = options?.map((option) => ({
        label: option,
        value: option,
      }))

      return data
    }

    const monthNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          style={{ lineHeight: 1 }}
        />
      )
    }

    // year template for the prime react ui calander
    const yearNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          className='p-ml-2'
          style={{ lineHeight: 1 }}
        />
      )
    }

    return (
      <>
        <div className='upload-documents-container'>
          {optionsDetail?.payload?.map((singleDoc, index) => {
            return (
              <Panel
                header={
                  singleDoc?.doc_type === 'Proof of identity and date of birth'
                    ? 'Proof of identity'
                    : singleDoc?.doc_type
                }
                toggleable
                key={index}
              >
                <div className='single-container' key={index}>
                  {/* <p>{singleDoc?.doc_type}</p> */}

                  {singleDoc?.has_options && (
                    <Dropdown
                      value={selectedOptions?.[singleDoc?.doc_type]}
                      name='Select category'
                      onChange={(e) =>
                        handleOptionChange(singleDoc?.doc_type, e.target.value)
                      }
                      placeholder='Select category'
                      options={transformedOptionObj(singleDoc?.options)}
                    />
                  )}

                  {singleDoc?.is_uploaded ? (
                    <p>
                      <span>&#x2705;</span> Document already uploaded
                    </p>
                  ) : (
                    <FileUpload
                      customUpload={true}
                      uploadHandler={uploadDocsCheck(singleDoc?.doc_type)}
                      auto
                      mode='advanced'
                      chooseLabel={'Upload Document'}
                      accept='.jpg,.png,.pdf'
                      emptyTemplate={emptyTemplate(singleDoc?.doc_type)}
                      itemTemplate={itemListTemplate(singleDoc?.doc_type)}
                    />
                  )}

                  {selectedOptions?.[singleDoc?.doc_type] &&
                    singleDoc?.doc_type ===
                      'Proof of identity and date of birth' && (
                      <>
                        <p style={{ fontSize: 16 }}>
                          {selectedOptions?.[singleDoc?.doc_type]} Document
                          Number
                        </p>

                        <InputText
                          placeholder='Document Number'
                          name='doc_number'
                          value={documentNumber}
                          onChange={(e) => {
                            let value = e?.target?.value?.replace(
                              /[^A-Za-z0-9\s]/g,
                              ''
                            )
                            setDocumentNumber(value)
                          }}
                        />
                      </>
                    )}

                  {(selectedOptions?.[singleDoc?.doc_type] ===
                    'Driver License' ||
                    selectedOptions?.[singleDoc?.doc_type] === 'Passport') &&
                    singleDoc?.doc_type ===
                      'Proof of identity and date of birth' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>
                        <Calendar
                          value={documentsExpiration}
                          dateFormat='yy/mm/dd'
                          monthNavigator={true}
                          yearNavigator={true}
                          monthNavigatorTemplate={monthNavigatorTemplate}
                          yearNavigatorTemplate={yearNavigatorTemplate}
                          yearRange={`${new Date().getFullYear()}:${
                            new Date().getFullYear() + 50
                          }`}
                          onChange={(e) =>
                            setDocumentsExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}

                  {(selectedOptions?.[singleDoc?.doc_type] ===
                    'Driver License' ||
                    selectedOptions?.[singleDoc?.doc_type] === 'Passport') &&
                    singleDoc?.doc_type === 'Proof of address' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>
                        <Calendar
                          value={addressDocExpiration}
                          dateFormat='yy/mm/dd'
                          monthNavigator={true}
                          yearNavigator={true}
                          monthNavigatorTemplate={monthNavigatorTemplate}
                          yearNavigatorTemplate={yearNavigatorTemplate}
                          yearRange={`${new Date().getFullYear()}:${
                            new Date().getFullYear() + 50
                          }`}
                          onChange={(e) =>
                            setAddressDocExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}

                  {/* {selectedOptions?.[singleDoc?.doc_type] === 'Passport' && (
                    <>
                      <p style={{ fontSize: 16 }}>Expiration Date</p>

                      <Calendar
                        value={documentsExpiration}
                        dateFormat='yy/mm/dd'
                        monthNavigator={true}
                        yearNavigator={true}
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        yearRange={`${new Date().getFullYear()}:${
                          new Date().getFullYear() + 50
                        }`}
                        onChange={(e) => setDocumentsExpiration(e.target.value)}
                        showIcon
                      />
                    </>
                  )} */}
                </div>
              </Panel>
            )
          })}

          {/* <Button
              disabled={!isAllDataFilled() || loading}
              label={loading ? 'Processing' : 'Submit'}
              icon={`${loading ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
              className='submit-button'
              onClick={() => {
                submitDocuments()
              }}
            /> */}

          {/* <Toast ref={toastErrorMsg} position='top-right' /> */}
        </div>
      </>
    )
  }

  // review all form data before submit
  const ReviewForm = () => {
    return (
      <>
        <Panel header={props?.t('reviewandcomplete')} toggleable>
          <section className='review-ib-form-section'>
            <div className='review-block-container'>
              <label>{props?.t('aboutyou')}</label>
              <p>
                {props?.t('fulllegalname')}
                <span>
                  {prefinraData?.payload?.first_name}{' '}
                  {prefinraData?.payload?.last_name}
                </span>
              </p>
              <p>
                {props?.t('email')}
                <span>{prefinraData?.payload?.email || '--'}</span>
              </p>
              <p>
                {props?.t('dateofbirth')}
                <span>
                  {prefinraData?.payload?.birth_day}/
                  {prefinraData?.payload?.birth_month}/
                  {prefinraData?.payload?.birth_year}
                </span>
              </p>
              {prefinraData?.payload?.ustaxpayer ? (
                <p>
                  {props?.t('socialsecuritynumber')}{' '}
                  <span>
                    {IBFormInfo?.ssn
                      ? `xxx-xxx-[${IBFormInfo?.ssn?.substring(
                          IBFormInfo?.ssn?.length - 4
                        )}]`
                      : '--'}
                  </span>
                </p>
              ) : (
                <p>
                  {props?.t('taxidentificationnumber')}{' '}
                  <span>
                    {IBFormInfo?.tax_id
                      ? `xxxxxx${IBFormInfo?.tax_id?.substring(
                          IBFormInfo?.tax_id?.length - 4
                        )}`
                      : '--'}
                  </span>
                </p>
              )}
              {IBFormInfo?.citizenship_confirmation === 'visa' && (
                <>
                  <p>
                    {props?.t('visatype')}
                    <span>{IBFormInfo?.visa_type}</span>
                  </p>
                  <p>
                    {props?.t('visaexpirationdate')}
                    <span>{IBFormInfo?.visa_expiration_date}</span>
                  </p>
                  {(IBFormInfo?.visa_type === 'B1' ||
                    IBFormInfo?.visa_type === 'B2') && (
                    <p>
                      {props?.t('dateofdeparturefromusa')}
                      <span>{IBFormInfo?.visa_departure_date}</span>
                    </p>
                  )}
                </>
              )}
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>{props?.t('address')}</label>
              <p>
                {props?.t('homeaddress')}
                <span>
                  {IBFormInfo?.street_1 || '--'}{' '}
                  {IBFormInfo?.street_2 && `, ${IBFormInfo?.street_2}`}
                </span>
                {prefinraData?.payload?.ustaxpayer ? (
                  <>
                    <span>
                      {IBFormInfo?.city || '--'}, {IBFormInfo?.state || '--'}{' '}
                      {IBFormInfo?.postal_code || '--'}
                    </span>
                    <span>{'United States'}</span>
                  </>
                ) : (
                  <>
                    <span>
                      {IBFormInfo?.city || '--'}, {IBFormInfo?.state || '--'}{' '}
                      {IBFormInfo?.postal_code || '--'}
                    </span>
                    <span>
                      {allCountries?.find(
                        (item) =>
                          item?.value ===
                          IBFormInfo?.international_country_address
                      )?.label || '--'}
                    </span>
                  </>
                )}
              </p>

              <p>
                {props?.t('citizenship')}{' '}
                <span>
                  {allCountries?.find(
                    (item) => item?.value === IBFormInfo?.citizenship
                  )?.label || '--'}
                </span>
              </p>
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>{props?.t('employment')}</label>
              <p>
                {props?.t('employmentstatus')}{' '}
                <span>{IBFormInfo?.employment_status || '--'}</span>
              </p>

              {(IBFormInfo?.employment_status === 'EMPLOYED' ||
                IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
                <>
                  <p>
                    {props?.t('employmentposition')}{' '}
                    <span>{IBFormInfo?.employment_position || '--'}</span>
                  </p>
                  <p>
                    {props?.t('employername')}{' '}
                    <span>{IBFormInfo?.company_name || '--'}</span>
                  </p>
                  <p>
                    {props?.t('employeraddress')}{' '}
                    <span>
                      {IBFormInfo?.employer_address_street_1 || '--'}{' '}
                      {IBFormInfo?.employer_address_street_2 &&
                        `, ${IBFormInfo?.employer_address_street_2}`}
                    </span>
                    <span>
                      {IBFormInfo?.employer_address_city || '--'},{' '}
                      {IBFormInfo?.employer_address_state || '--'}{' '}
                      {IBFormInfo?.employer_address_postal_code || '--'}
                    </span>
                    <span>{IBFormInfo?.employer_address_country || '--'}</span>
                  </p>
                </>
              )}
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>{props?.t('financialprofile')}</label>

              <p>
                {props?.t('fundingsource')}
                <span>
                  {sourceOfWealthOptions?.find(
                    (item) => item.value === IBFormInfo?.primary_funds
                  )?.label || '--'}
                </span>
              </p>

              <p>
                {props?.t('incomerange')}
                <span>
                  {FinancialAnnualList?.find(
                    (item) => item.value === IBFormInfo?.annual_net_income
                  )?.label || '--'}
                </span>
              </p>

              <p>
                {props?.t('networthrange')}
                <span>
                  {FinancialNetWorthList?.find(
                    (item) => item.value === IBFormInfo?.net_worth
                  )?.label || '--'}
                </span>
              </p>

              <p>
                {props?.t('liquidnetworthrange')}
                <span>
                  {FinancialNetWorthList?.find(
                    (item) => item.value === IBFormInfo?.liquid_net_worth
                  )?.label || '--'}
                </span>
              </p>

              <p>
                {props?.t('investingtimeline')}
                <span>
                  {PlanInvestmentList?.find(
                    (item) => item.value === IBFormInfo?.investment_plan
                  )?.label || '--'}
                </span>
              </p>

              <p>
                {props?.t('risktolerance')}
                <span>
                  {RiskToleranceList?.find(
                    (item) => item.value === IBFormInfo?.risk_tolerance
                  )?.label || '--'}
                </span>
              </p>
            </div>

            {!prefinraData?.payload?.ustaxpayer ? (
              <div className='multiple-checkbox'>
                <div className='checkbox-container'>
                  <Checkbox
                    name='alpacaInternationalAgree1'
                    value={alpacaInternationalAgree1}
                    onChange={() =>
                      setAlpacaInternationalAgree1(!alpacaInternationalAgree1)
                    }
                    checked={alpacaInternationalAgree1}
                  />
                  <label htmlFor='ingredient1' className='ml-2'>
                    {props?.t('certifynotusperson')}
                  </label>
                </div>
                <div className='checkbox-container'>
                  <Checkbox
                    name='alpacaInternationalAgree2'
                    value={alpacaInternationalAgree2}
                    onChange={() =>
                      setAlpacaInternationalAgree2(!alpacaInternationalAgree2)
                    }
                    checked={alpacaInternationalAgree2}
                  />
                  <div>
                    <label htmlFor='ingredient1' className='ml-2'>
                      {props?.t('declareunderpenaltiesofperjury')}
                    </label>
                    <ul>
                      <li>{props?.t('individualisbeneficialowner')}</li>
                      <li>{props?.t('notusperson')}</li>
                      <li>{props?.t('formrelatesto')}</li>
                      <ol className='letter-list'>
                        <li>{props?.t('incomenoteffectivelyconnected')}</li>
                        <li>{props?.t('incomeeffectivelyconnected')}</li>
                        <li>{props?.t('partnershipsharetaxableincome')}</li>
                        <li>{props?.t('partnershiptransferrealizedamount')}</li>
                      </ol>
                      <li>{props?.t('treatycountryresident')}</li>
                      <li>{props?.t('brokertransactions')}</li>
                    </ul>
                    <label>{props?.t('authorizeformtobeprovided')}</label>
                  </div>
                </div>
              </div>
            ) : null}

            <div className='agreement-container'>
              <label htmlFor=''>{props?.t('agreements')}</label>
              <a
                href='https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Alpaca Customer Agreement
              </a>
              <a
                href='https://www.forescite.ai/mobile-disclosures-forescite'
                target='_blank'
                rel='noreferrer'
              >
                Investall Disclosures
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/UseAndRisk.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Use and Risk
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/TermsAndConditions.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Terms and Conditions
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/PrivacyNotice.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Notice
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/PFOF.pdf'
                target='_blank'
                rel='noreferrer'
              >
                PFOF
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Margin Disclosure Statement
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/ExtHrsRisk.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Extended Hours Trading Risk
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/BCPSummary.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Business Continuity Plan Summary
              </a>
              <a
                href='https://files.alpaca.markets/disclosures/library/FormCRS.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Form CRS
              </a>
            </div>
          </section>

          <div className='multiple-checkbox'>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeAlpacaRead'
                value={agreeAlpacaRead}
                onChange={() => setAgreeAlpacaRead(!agreeAlpacaRead)}
                checked={agreeAlpacaRead}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                {props?.t('readandagreetoterms')}
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeAlpacaSign'
                value={agreeAlpacaSign}
                onChange={() => setAgreeAlpacaSign(!agreeAlpacaSign)}
                checked={agreeAlpacaSign}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                {props?.t('understandelectronicagreement')}
              </label>
            </div>
          </div>
        </Panel>
      </>
    )
  }

  // the component when finished the submiited the form
  const SubmittedSuccessComp = () => {
    return (
      <div className='post-submitted-container'>
        <p>{props?.t('account_pending_approval')}</p>

        <span>{props?.t('account_creation_pending')}</span>

        <Button
          label={props?.t('go_to_dashboard')}
          onClick={async () => {
            try {
              setDashboardLoading(true)
              await props?.getEmail()
              await props?.getUserHoldings()
              if (props?.closeSubscriptionDialog) {
                props?.closeSubscriptionDialog()
              }
              // This should only go off if account has been rejected already
              props?.finishCloseModal()
              props?.setIbFormModel(false)
              // if (props?.closeModal) {
              //   console.log('Finished retrying account creation')
              //   props?.closeModal()
              // }
              // window.location.reload()
            } catch (e) {
              console.log('loading dashboard error', e)
            } finally {
              setDashboardLoading(false)
            }
          }}
          loading={dashboardLoading}
        />
      </div>
    )
  }

  const IBFooterContent = () => {
    //get the label name dynamically from the step index
    const getLabletName = () => {
      switch (activeIndex) {
        case 0:
          return props?.t('next') + `: ${props?.t('employment')}`
        case 1:
          return props?.t('next') + `: ${props?.t('financial')}`
        case 2:
          return props?.t('next') + `: ${props?.t('investments')}`
        case 3:
          return props?.t('next') + `: ${props?.t('acknowledgment')}`
        case 4:
          return props?.t('next') + `: ${props?.t('reviewandcomplete')}`
        case 5:
          if (submitFormButtonClick) {
            return props?.t('processing')
          } else {
            return props?.t('acceptandcontinue')
          }

        default:
          return props?.t('next') + `: ${props?.t('employment')}`
      }
    }

    return (
      <div className='ib-dialog-footer-content'>
        <p className='english-characters-msg'>
          *{props?.t('only_english_characters')}
        </p>
        <Button
          label={getLabletName()}
          onClick={async () => {
            // mean it is last stepm and the button is clicked to submitted
            await submitCheckpointData()
            if (activeIndex === 5) {
              if (!submitFormButtonClick) {
                setSubmitFormButtonClick(true)
                if (await submitForm()) {
                  setSubmitFormButtonClick(false)
                  setIsFormSubmitted(true)
                }
                setSubmitFormButtonClick(false)
              }
            } else {
              // if not +1 for current index
              setLastStepIndex((prev) => prev + 1)
              setActiveIndex((prev) => prev + 1)
            }
          }}
          loading={submitFormButtonClick}
        />
      </div>
    )
  }

  return (
    <Dialog
      header={
        <>
          {!isFormSubmitted && (
            <div className='unremoveable-header-container'>
              <p className='unremoveable-header'>
                {props?.t('createinvestingaccount')}
              </p>

              {!props?.closeModal && (
                <p
                  onClick={() => {
                    props?.signOut()
                  }}
                  className='unremoveable-signout'
                >
                  {props?.t('signout')}
                </p>
              )}
            </div>
          )}

          {!isFormSubmitted && (
            <div>
              <Steps
                model={IBFormSteps}
                activeIndex={activeIndex}
                onSelect={async (e) => {
                  await submitCheckpointData()
                  setActiveIndex(e.index)
                }}
                readOnly={false}
              />
            </div>
          )}
        </>
      }
      footer={!isFormSubmitted && IBFooterContent}
      visible={props?.ibFormModel}
      closable={props?.closeModal ? true : false} // no "x" button
      onHide={() => (props?.closeModal ? props?.closeModal() : {})}
      className='IB-form-dialog'
    >
      <>
        {isLoadingData ? (
          <SkeletonLoading />
        ) : (
          // dynamically load the corresponding form component based on current index step or if user clicked submit button
          <div className='ib-questionnaire-container'>
            {activeIndex === 0 && !isFormSubmitted && <>{PersonalForm()}</>}
            {activeIndex === 1 && !isFormSubmitted && <>{EmploymentForm()}</>}
            {activeIndex === 2 && !isFormSubmitted && <>{FinancialForm()}</>}
            {activeIndex === 3 && !isFormSubmitted && <>{InvestmentForm()}</>}
            {activeIndex === 4 && !isFormSubmitted && (
              <>{AcknowledgmentForm()}</>
            )}
            {/* {activeIndex === 5 && !isFormSubmitted && (
              <>{UploadDocumentsForm()}</>
            )} */}
            {activeIndex === 5 && !isFormSubmitted && <>{ReviewForm()}</>}
            {isFormSubmitted && <>{SubmittedSuccessComp()}</>}
          </div>
        )}
        <Toast ref={toastErrorMsg} position='top-right' />
      </>
    </Dialog>
  )
}

const IBQuestionnaire = (props) => {
  const toastErrorMsg = useRef()

  //personalization states
  const [personalModel, setPersonalModel] = useState(false)
  const [selectedSectors, setSelectedSectors] = useState(null)
  const [sectorLists, setSectorLists] = useState(null)
  const [selectedGoal, setSelectedGoal] = useState(null)
  const [selectedGoalText, setSelectedGoalText] = useState(null)
  const [selectedCauses, setSelectedCauses] = useState([])

  const [isPersonalButtonClicked, setIsPersonalButtonClicked] = useState(false)
  const [personalSetupError, setPersonalSetupError] = useState('')

  //ib form states
  const [ibFormModel, setIbFormModel] = useState(true)

  // const [prefinraData, setPrefinraData] = useState(null)
  const [countriesData, setCountriesData] = useState(null)
  // const [activeIndex, setActiveIndex] = useState(1)

  const shortTerm = [
    { label: 'Savings', text: 'Short Term - Savings', value: 'Savings' },
    {
      label: 'Emergencies',
      text: 'Short Term - Emergencies',
      value: 'Emergencies',
    },
  ]
  const mediumTerm = [
    {
      label: 'Save for vacation',
      text: 'Medium Term - Save for vacation',
      value: 'Save for vacation',
    },
    {
      label: 'Education fund',
      text: 'Medium Term - Education fund',
      value: 'Education fund',
    },
  ]
  const longTerm = [
    {
      label: 'Buy a home',
      text: 'Long Term - Buy a home',
      value: 'Buy a home',
    },
    {
      label: 'Save for retirement',
      text: 'Long Term - Save for retirement',
      value: 'Save for retirement',
    },
  ]
  const personalCauses = [
    { label: 'Environment', value: 'Environment' },
    {
      label: 'Social Responsibility',
      value: 'Social Responsibility',
    },
    {
      label: 'Fairness',
      value: 'Fairness',
    },
    {
      label: 'Gender Equality',
      value: 'Gender Equality',
    },
    {
      label: 'Racial Equality',
      value: 'Racial Equality',
    },
  ]

  useEffect(() => {
    // fetchAllCountries()
    // getPreFinraInfo()
    fetchSectors()
  }, [])

  const fetchAllCountries = async () => {
    try {
      let res = await axios.get(`${BaseURL}/drivewealth/preset`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setCountriesData(res?.data?.countries_all)

      return
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  // fetch secotors options from api call
  const fetchSectors = async () => {
    setPersonalSetupError('')
    let body = {
      filters: {},
      range_filters: [],
    }

    try {
      let res = await axios.post(
        `${BaseURL}/search/ticker-filter-with-forecast`,
        body,
        { withCredentials: false }
      )

      // get sectos dropdown
      const sectorsLists = Array.from({
        length: res?.data?.content?.sector?.length,
      })?.map((_, i) => ({
        label: `${res?.data?.content?.sector?.[i]}`,
        value: res?.data?.content?.sector?.[i],
      }))

      setSectorLists(sectorsLists)
      setPersonalSetupError('')
    } catch (error) {
      console.log(error)
      setPersonalSetupError('Something went wrong. Please try again later')
    }
  }

  // validate form and submit the form after valdiated
  const submitPersonalizationInfo = async () => {
    setIsPersonalButtonClicked(true)

    if (
      !selectedSectors ||
      !selectedGoalText ||
      !selectedCauses ||
      selectedCauses.length === 0
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Form submission failed',
        detail: 'Please make sure all fields are filled',
        life: 3000,
      })
      setIsPersonalButtonClicked(false)

      return
    }

    let body = {
      filters: {
        sector: selectedSectors,
        financial_goals: selectedGoalText,
        causes: selectedCauses,
      },
    }

    try {
      let res = await axios.post(
        `${BaseURL}/search/personalization-questionnaire`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      if (res?.data?.success) {
        setIsPersonalButtonClicked(false)
        setPersonalModel(false)
        setIbFormModel(true)
      } else {
        // is false
        setIsPersonalButtonClicked(false)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Setup personalization failed, please try again',
          life: 3000,
        })
      }
    } catch (error) {
      console.log(error)
      setIsPersonalButtonClicked(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Setup personalization failed, please try again',
        life: 3000,
      })
    }
  }

  const PersonalFooterContent = (
    <div className='dialog-footer-content'>
      <Button
        label='Skip for now'
        onClick={() => {
          setPersonalModel(false)
          setIbFormModel(true)
        }}
        className='p-button-danger p-button-text'
        disabled={isPersonalButtonClicked}
      />
      <Button
        onClick={() => submitPersonalizationInfo()}
        autoFocus
        label={isPersonalButtonClicked ? 'Processing' : 'CONTINUE'}
        icon={`${
          isPersonalButtonClicked ? 'pi pi-spin pi-spinner' : 'no-icon'
        }`}
        disabled={isPersonalButtonClicked}
      />
    </div>
  )

  // for onchange event of multiple checkbox selected or not selected
  const onCausesChange = (e) => {
    let _selectedCauses = [...selectedCauses]

    if (e.checked) {
      _selectedCauses.push(e.value)
    } else {
      _selectedCauses = _selectedCauses.filter((cause) => cause !== e.value)
    }

    setSelectedCauses(_selectedCauses)
  }

  return (
    <>
      <Dialog
        header={
          <div className='unremoveable-header-container'>
            <p className='unremoveable-header'>Personalization Setup</p>

            <p
              onClick={() => {
                props?.signOut()
              }}
              className='unremoveable-signout'
            >
              Sign out
            </p>
          </div>
        }
        visible={personalModel}
        style={{ width: props?.isSmallScreen ? '90vw' : '60vw' }}
        closable={false} // no "x" button
        footer={PersonalFooterContent}
      >
        {!personalSetupError ? (
          <div className='personalization-content'>
            <div className='question-container'>
              <label className='label-question'>
                what sectors are you interested to invest in?
                <span>(select up to 5)</span>
              </label>

              <MultiSelect
                value={selectedSectors}
                onChange={(e) => {
                  if (e.value.length <= 5) {
                    setSelectedSectors(e.value)
                  }
                }}
                options={sectorLists}
                display='chip'
                placeholder='Select Sectors'
                maxSelectedLabels={5}
                className=''
                disabled={isPersonalButtonClicked}
              />
            </div>

            <div className='question-container financial-goals-container'>
              <label className='label-question'>
                what are your financial goals?
                <span>
                  (Choose a goal that best applies to your current situation)
                </span>
              </label>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={shortTermImg} alt='' />
                  <span>Short Term</span>
                </label>

                <div className='term-content'>
                  {shortTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          name='goal'
                          checked={selectedGoal === term.value}
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={mediumTermImg} alt='' />
                  <span>Medium Term</span>
                </label>

                <div className='term-content'>
                  {mediumTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          checked={selectedGoal === term.value}
                          name='goal'
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={longTermImg} alt='' />
                  <span>Long Term</span>
                </label>

                <div className='term-content'>
                  {longTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          checked={selectedGoal === term.value}
                          name='goal'
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className='question-container'>
              <label className='label-question'>
                Select the causes that are important to you
                <span>(select as many as you like)</span>
              </label>

              {personalCauses.map((cause) => {
                return (
                  <div key={cause.label} className='option-container'>
                    <Checkbox
                      inputId={cause.label}
                      name='causes'
                      value={cause.value}
                      onChange={onCausesChange}
                      checked={selectedCauses.some(
                        (item) => item === cause.value
                      )}
                      disabled={isPersonalButtonClicked}
                    />
                    <label htmlFor={cause.label} className='ml-2'>
                      {cause.label}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div>{personalSetupError}</div>
        )}
        <Toast ref={toastErrorMsg} position='top-right' />
      </Dialog>

      <IBQuestionnaireModel
        t={props?.t}
        signOut={props?.signOut}
        ibFormModel={ibFormModel}
        isSmallScreen={props?.isSmallScreen}
        user={props?.user}
        setIbFormModel={setIbFormModel}
        // closeModal={() => {
        //   setIbFormModel(false)
        // }}
        getIBStatus={props?.getIBStatus}
        // allCountries={countriesData}
        closeModal={props?.closeModal}
        closeSubscriptionDialog={props?.closeSubscriptionDialog}
        getEmail={props?.getEmail}
        finishCloseModal={props?.finishCloseModal}
        getUserHoldings={props?.getUserHoldings}
        // closeable={props?.closeable}
      />
    </>
  )
}

export default IBQuestionnaire
