import React, { useEffect, useState } from 'react'
import '../../scss/components/IBStatusScreen.scss'
import { IoIosAlert } from 'react-icons/io'
import { Dialog } from 'primereact/dialog'
import formatCurrency from '../../utility-functions/formatCurrency'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import BaseURL from '../../components/Endpoint'
import Axios from 'axios'
import WireWithdrawDialog from '../components/WireWithdrawDialog'
import BackIcon from '../../assets/product-activation-back-icon.svg'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import LinkPlaidBtn from '../components/LinkPlaidBtn'
import ConfirmationDialog from '../components/ConfirmationDialog'
import Endpoint from '../../components/Endpoint'
import { InputNumber } from 'primereact/inputnumber'

export default function IBLiquidateDWAccountsCard(props) {
  const [showDialog, setShowDialog] = useState(false)
  const [showLiquidateConfirmation, setShowLiqudateConfirmation] =
    useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedPosition, setSelectedPosition] = useState(null)

  const [sellingPosition, setSellingPosition] = useState('main')
  const [sellConfirmation, setSellConfirmation] = useState(false)

  const [showWithdrawDialog, setShowWithdrawDialog] = useState(false)
  const [withdrawPosition, setWithdrawPosition] = useState('main')
  const [withdrawConfirmation, setWithdrawConfirmation] = useState(false)
  const [withdrawOptions, setWithDrawOptions] = useState(0)
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [showWireWithdrawDialog, setShowWireWithdrawDialog] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [withdrawStatus, setWithdrawStatus] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [showConfirmationDialog, toggleShowConfirmationDialog] = useState(false)
  const [productAccounts, setProductAccounts] = useState([])
  const [inactiveProductAccounts, setInactiveProductAccounts] = useState([])
  const [bankingAccounts, setBankingAccounts] = useState([])
  const [selectedFromAccount, setSelectedFromAccount] = useState(null)
  const [selectedToAccount, setSelectedToAccount] = useState(null)
  const [amountValue, setAmountValue] = useState(0)

  const noOptions = [{ label: 'No available options', value: null }]

  useEffect(() => {
    filterAccounts()
  }, [])

  useEffect(() => {
    if (selectedPosition) setShowLiqudateConfirmation(true)
  }, [selectedPosition])

  const filterAccounts = () => {
    let productAccounts = []
    let bankingAccounts = []
    let inactiveProductAccounts = []

    props.accounts.forEach((account) => {
      if (account.inst && account.inst === 'DriveWealth') {
        if (!account.product && account.product_assigned !== 'empty') {
          inactiveProductAccounts.push({
            label: `${getProductName(account.product_assigned)} ACCOUNT`,
            value: account,
          })
        }
        if (account.product_assigned !== 'empty')
          productAccounts.push({
            label: `${getProductName(account.product_assigned)} ACCOUNT`,
            value: account,
          })
      } else if (account.type && account.type === 'depository') {
        bankingAccounts.push({
          label: `${account.inst} ${account.mask}`,
          value: account,
        })
      }
    })
    setProductAccounts(productAccounts)
    setInactiveProductAccounts(inactiveProductAccounts)
    setBankingAccounts(bankingAccounts)
    setSelectedFromAccount(null)
    setSelectedToAccount(null)
  }

  const getProductName = (product_assigned) => {
    switch (product_assigned) {
      case 'equity':
        return 'STOCKS'
      case 'goal':
        return 'GOAL'
      case 'group':
        return 'AI STOCK TRADER'
      case 'robo':
        return 'ROBO'
      default:
        return ''
    }
  }

  const sellPosition = async () => {
    try {
      setSellingPosition('loading')

      // Call Liquidate API
      await sellAllShares()
      await props?.updateAccs()
      // throw new Error('Parameter is not a number!')
      setSellConfirmation(true)
    } catch (error) {
      console.log('liqudate error', error)
      setSellConfirmation(false)
    }
    setSellingPosition('finished')
  }

  const sellAllShares = async () => {
    if (selectedPosition?.type?.toLowerCase() === 'crypto') {
      await sellCryto()
    } else {
      // it is stock
      await sellStocks()
    }
    return
  }

  const sellStocks = async () => {
    let body = {
      dwAccountID: selectedPosition?.accountId,
      orderType: 'MARKET', // LIMIT / MARKET
      symbol: selectedPosition?.symbol,
      side: 'SELL', // BUY / SELL
      quantity: selectedPosition?.quantity,
    }

    console.log('body in sellstock', body)
    try {
      const url = `${BaseURL}/mobile/dw/trades/submit`

      const header = {
        withCredentials: false,
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      }
      const res = await Axios.post(url, body, header)

      if (res && res?.success) {
        console.log('successfuly sell stock')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sellCryto = async () => {
    let body = {
      type: 'MARKET',
      ticker: selectedPosition?.symbol,
      side: 'SELL',
      quantity: selectedPosition?.quantity,
    }

    console.log('body in sellCryto', body)
    try {
      const url = `${BaseURL}/apex-cp/order?account_id=${selectedPosition?.accountId}`

      const header = {
        withCredentials: false,
        headers: {
          Authorization: 'Bearer ' + props.user.idToken.jwtToken,
        },
      }
      const res = await Axios.post(url, body, header)

      if (
        res &&
        res?.payload &&
        res?.payload.status &&
        res?.payload.status === 'COMPLETE'
      ) {
        console.log('successfuly sell crypto')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const closePositionDialog = () => {
    setShowLiqudateConfirmation(false)
    setSelectedPosition(null)
    setSellingPosition('main')
  }

  const formatCurrency = (val) => {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    return numberFormat.format(val)
  }

  // Withdraw functions START

  const withdrawAccount = async () => {
    try {
      setWithdrawPosition('loading')

      // Call Liquidate API

      // throw new Error('Parameter is not a number!')
      setWithdrawConfirmation(true)
    } catch (error) {
      console.log('wthidraw error', error)
      setWithdrawConfirmation(false)
    }
    setWithdrawPosition('finished')
  }

  const toggleError = (msg) => {
    setFormErrorMessage(msg)
    setTimeout(() => {
      setFormErrorMessage(msg)
    }, 3000)
  }

  const submitAPI = () => {
    return withdraw()
  }
  const handleAmountChange = (value) => {
    setAmountValue(value)
  }
  const withdraw = async () => {
    let body = {
      plaidAccountID: selectedToAccount.accountId,
      dwAccountID: selectedFromAccount.accountId,
      amount: amountValue,
      note: 'Transfer',
    }
    // this.setState({ isLoading: true })
    setWithdrawLoading(true)
    return await Axios.post(
      `${Endpoint}/mobile/withdraw-plaid`,
      {
        plaidAccountID: selectedToAccount.accountId,
        dwAccountID: selectedFromAccount.accountId,
        amount: amountValue,
        note: 'Transfer',
      },
      {
        headers: {
          Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then(async (response) => {
        if (response?.data?.Withdraw?.batch) {
          await props.getAllAccountInfo()
          setWithdrawLoading(false)
          setWithdrawStatus(true)
          // this.setState({ isLoading: false, status: true })
          setTimeout(() => {
            // this.setState({ status: null })
            setWithdrawStatus(null)
          }, 5000)
          return true
        } else {
          setWithdrawLoading(false)
          setWithdrawStatus(false)
          // this.setState({ isLoading: false, status: false })
          setTimeout(() => {
            // this.setState({ status: null })
            setWithdrawStatus(null)
          }, 5000)
          return false
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        // this.setState({ isLoading: false, status: false })
        setWithdrawLoading(false)
        setWithdrawStatus(false)
        setTimeout(() => {
          // this.setState({ status: null })
          setWithdrawStatus(null)
        }, 5000)
        return false
      })
  }

  const toggleWireWithdrawDialog = () => {
    setShowWireWithdrawDialog(!showWireWithdrawDialog)
  }

  const withdrawValidateForm = () => {
    if (selectedFromAccount && selectedToAccount && amountValue) {
      // if (this.state.activeTab === 0) {
      //   if (this.state.amountValue <= 5000) {
      //     setConfirmMessage(
      //       `Are you sure you want to make a deposit of ${formatCurrency(
      //         this.state.amountValue
      //       )}?`
      //     )
      //     toggleShowConfirmationDialog(true)
      //     // this.setState({
      //     //   showConfirmationDialog: true,
      //     //   confirmMessage: `Are you sure you want to make a deposit of ${this.props.formatCurrency(
      //     //     this.state.amountValue
      //     //   )}?`,
      //     // })
      //     // this.submitAPI()
      //   } else {
      //     toggleError(props.t('amountcannotbegreaterthan5000'))
      //   }
      // } else if (this.state.activeTab === 1) {
      //   if (this.state.selectedFromAccount !== this.state.selectedToAccount) {
      //     if (
      //       this.state.amountValue <=
      //       this.state.selectedFromAccount.balances[0].fundsInvestable
      //     ) {
      //       if (this.state.canTrade?.transfer) {
      //         this.setState({
      //           showConfirmationDialog: true,
      //           confirmMessage: `Are you sure you want to make a transfer of ${this.props.formatCurrency(
      //             this.state.amountValue
      //           )}?`,
      //         })
      //         // this.submitAPI()
      //       } else {
      //         toggleError(props.t('transfersarenotavailableatthistime'))
      //       }
      //     } else {
      //       toggleError(props.t('cannottransfermorethanyouown'))
      //     }
      //   } else {
      //     toggleError('Cannot transfer between same accounts')
      //   }
      // } else {
      //   if (this.state.amountValue >= 1) {
      //     if (
      //       this.state.amountValue <=
      //       this.state.selectedFromAccount.balances[0]
      //         .cashAvailableForWithdrawal
      //     ) {
      //       this.setState({
      //         showConfirmationDialog: true,
      //         confirmMessage: `Are you sure you want to make a withdrawal of ${this.props.formatCurrency(
      //           this.state.amountValue
      //         )}?`,
      //       })
      //       // this.submitAPI()
      //     } else {
      //       toggleError(props.t('cannotwithdrawmorethanyouown'))
      //     }
      //   } else {
      //     toggleError(props.t('amountmustbeatleast1dollar'))
      //   }
      // }
      if (amountValue >= 1) {
        if (
          amountValue <=
          selectedFromAccount.balances[0].cashAvailableForWithdrawal
        ) {
          toggleShowConfirmationDialog(true)
          setConfirmMessage(
            `Are you sure you want to make a withdrawal of ${formatCurrency(
              amountValue
            )}?`
          )
          // this.submitAPI()
        } else {
          toggleError(props.t('cannotwithdrawmorethanyouown'))
        }
      } else {
        toggleError(props.t('amountmustbeatleast1dollar'))
      }
    } else {
      toggleError(props.t('allfieldsarerequired'))
    }
  }
  const fromAccountForm = (accounts, showBalance) => {
    return (
      <div className='from-account-form-container'>
        <p className='label'>{props.t('from')}:</p>
        <Dropdown
          value={
            selectedFromAccount?.length === 0 ? noOptions : selectedFromAccount
          }
          options={accounts}
          onChange={(e) => handleFromAccountChange(e.value)}
          placeholder={props.t('selectanaccount')}
        />
        {showBalance && selectedFromAccount ? (
          <p className='balance'>
            {props.t('available')}:{' '}
            <span className='dollar-amount'>
              {formatCurrency(displayFromBalance())}
            </span>
          </p>
        ) : null}
      </div>
    )
  }

  const toAccountForm = (accounts, showBalance) => {
    return (
      <div className='to-account-form-container'>
        <p className='label'>{props.t('to')}:</p>
        <Dropdown
          value={
            selectedToAccount?.length === 0 ? noOptions : selectedToAccount
          }
          options={accounts}
          onChange={(e) => handleToAccountChange(e.value)}
          placeholder={props.t('selectanaccount')}
        />
        {showBalance && selectedToAccount ? (
          <p className='balance'>
            {props.t('balance')}:{' '}
            <span className='dollar-amount'>
              {formatCurrency(displayToBalance())}
            </span>
          </p>
        ) : null}
        <div className='link-plaid-btn'>
          <LinkPlaidBtn
            user={props.user}
            getAllAccountInfo={props.getAllAccountInfo}
            isBank={true}
            showButton={true}
            reLink={false}
            t={props.t}
          />
        </div>
      </div>
    )
  }

  const displayFromBalance = () => {
    return selectedFromAccount.balances[0].cashAvailableForWithdrawal
  }

  const displayToBalance = () => {
    return selectedToAccount.balances[0].virtualAccountValue
  }

  const handleFromAccountChange = (value) => {
    setSelectedFromAccount(value)
  }

  const handleToAccountChange = (value) => {
    setSelectedToAccount(value)
  }

  const showMessage = () => {
    if (withdrawStatus) {
      return (
        <p className='success'>
          {props.t('transactionhasbeensuccessfullycompleted')}
        </p>
      )
    } else if (withdrawStatus === false) {
      return <p className='failure'>{props.t('somethingwentwrong')}</p>
    } else return null
  }

  // Withdraw functions END

  const getAccountCard = (account, name, product_assigned) => {
    if (account) {
      return (
        <div
          onClick={() => {
            console.log('account', account)
            setSelectedAccount(account)
          }}
          style={{
            height: '100px',
            marginBottom: '16px',
            cursor: 'pointer',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0rem 1rem',
            position: 'relative',
            boxShadow:
              '-3px -1px 5px 0px rgba(210, 210, 210, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.21) inset, 3px 2px 4px 0px rgba(0, 0, 0, 0.20)',
            borderRadius: '19px',
          }}
        >
          <div style={{ width: 'auto', margin: 'auto', marginLeft: '0' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                gap: '0.2rem',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  margin: '0',
                  color: '#3627e4',
                }}
              >
                {name}
              </p>
              <p
                style={{
                  fontSize: '12px',
                  margin: '0',
                  color: '#3627e4',
                }}
              >
                {product_assigned?.toUpperCase() ?? ''}
              </p>
            </div>
          </div>
          <div
            style={{
              width: 'auto',
              margin: 'auto',
              marginRight: '0',
              display: 'block',
              marginBottom:
                account?.balances[0].virtualAccountValue === 0 ||
                (!account?.product &&
                  name !== 'STOCK' &&
                  name !== 'CRYPTO' &&
                  name !== 'SELF TRADING')
                  ? '2rem'
                  : 'auto',
            }}
          >
            <p
              style={{
                color: 'rgba(128,128,128,.7)',
                fontSize: '12px',
                margin: '0',
              }}
            >
              Account Value
            </p>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                margin: '0',
                color: '#3627e4',
              }}
            >
              {formatCurrency(account.balances[0].virtualAccountValue)}
            </p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderCardsOrAccount = () => {
    switch (selectedAccount?.inst) {
      case 'DriveWealth':
      case 'Apex':
        return (
          <div className='ib-liquidate-account-info'>
            <p className='back-btn' onClick={() => setSelectedAccount(null)}>
              <BiChevronLeft />
              Back to all accounts
            </p>
            <div className='total-portofolio-value-container'>
              <p className='portofolio-value'>
                {formatCurrency(selectedAccount?.balances?.[0]?.current) ??
                  '--'}
              </p>
              <p>Total Portfolio Value</p>
            </div>
            <div
              className='withdraw-btn'
              onClick={() => setShowWithdrawDialog(true)}
            >
              <p>Withdraw Funds</p>
              <BiChevronRight />
            </div>
            {selectedAccount?.holdings?.length !== 0 && (
              <>
                <div className='section-title'>
                  <p className='title-text'>Holdings</p>
                  <p>
                    Buying Power:{' '}
                    {'$' + selectedAccount?.balances?.[0]?.buyingPower ?? '$0'}
                  </p>
                </div>
                <div className='portofolio-positions'>
                  <div className='position-row header'>
                    <div className='position-side left'>
                      <p>Symbol</p>
                    </div>
                    <div className='position-side right'>
                      <p>Mkt Value</p>
                    </div>
                  </div>
                  {selectedAccount?.holdings?.map((position) => {
                    return (
                      <div
                        className='position-row'
                        onClick={() => setSelectedPosition(position)}
                      >
                        <div className='position-side left'>
                          <p className='pos-name'>{position?.symbol}</p>
                          <p className='pos-share'>
                            {position?.quantity} Shares
                          </p>
                        </div>
                        <div className='position-side right'>
                          <p>{formatCurrency(position?.instValue)}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        )
      default:
        return props?.filteredAccounts?.map((account, i) => {
          return (
            <div key={i}>
              {getAccountCard(
                account,
                account?.name_unmasked ?? account?.name,
                account?.product_assigned
              )}
            </div>
          )
        })
    }
  }

  const renderSellingPosition = () => {
    switch (sellingPosition) {
      case 'finished':
        return (
          <div className='liquidate-position-finished'>
            {sellConfirmation ? (
              <p>Your {selectedPosition?.symbol} shares have been sold</p>
            ) : (
              <p>
                There was an error when trying to liquidate. Please try again
              </p>
            )}
            <div className='liquidate-position-buttons'>
              <button
                onClick={() => {
                  closePositionDialog()
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )
      case 'loading':
        return (
          <div className='liquidate-position-loader-container'>
            <p>Liquidating</p>
            <div className='liquidate-position-loader'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )
      case 'main':
      default:
        return (
          <>
            <p className='liquidate-position-title'>
              This will sell all your {selectedPosition?.symbol} stock(s) from
              this account
            </p>
            <p>
              Your {selectedAccount?.inst ?? ''} brokerage accounts are in
              liquidate only state. At this point you can only liqudate your
              assets and withdraw the amount. This action will sell your{' '}
              {selectedPosition?.quantity} quantity of{' '}
              {selectedPosition?.symbol} at market price.
            </p>
            <div className='liquidate-position-buttons'>
              <button
                onClick={() => {
                  closePositionDialog()
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  sellPosition()
                }}
              >
                Continue
              </button>
            </div>
          </>
        )
    }
  }

  const renderWithdrawFunds = () => {
    switch (withdrawPosition) {
      case 'finished':
        return (
          <div className='liquidate-position-finished'>
            {withdrawConfirmation ? (
              <p>Your funds have been withdrawn</p>
            ) : (
              <p>
                There was an error when trying to withdraw. Please try again
              </p>
            )}
            <div className='liquidate-position-buttons'>
              <button
                onClick={() => {
                  setShowWithdrawDialog(false)
                  setWithdrawPosition('main')
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )
      case 'loading':
        return (
          <div className='liquidate-position-loader-container'>
            <p>Withdrawing</p>
            <div className='liquidate-position-loader'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )
      case 'main':
      default:
        return (
          <>
            <p className='liquidate-position-title'>
              This will withdraw all funds from the account{' '}
              {selectedAccount?.name_unmasked}
            </p>
            <div>
              {withdrawOptions === 0 && (
                <div className='option-buttons'>
                  <button
                    className='option-btn'
                    onClick={() => {
                      setWithDrawOptions(1)
                    }}
                  >
                    {props.t('achlinking')} <br />
                    {props.t('realtimeverification')}
                    <br />({props.t('usbankaccountsonly')})
                    {/* <img src={WhiteArrow} className='white-arrow' /> */}
                  </button>
                  <button
                    className='option-btn'
                    onClick={() => {
                      setWithDrawOptions(2)
                    }}
                  >
                    {props.t('wirewithdraw')}
                    {/* <img src={WhiteArrow} className='white-arrow' /> */}
                  </button>
                </div>
              )}
              <>
                {withdrawOptions === 1 && (
                  <>
                    <img
                      src={BackIcon}
                      height={25}
                      width={25}
                      onClick={() => {
                        setWithDrawOptions(0)
                      }}
                      style={{ cursor: 'pointer' }}
                      alt=''
                    />{' '}
                    <div className='tab-container'>
                      <div
                        style={{
                          filter: withdrawLoading ? 'blur(5px)' : '',
                          pointerEvents: withdrawLoading ? 'none' : '',
                        }}
                      >
                        {fromAccountForm(inactiveProductAccounts, true)}

                        {toAccountForm(bankingAccounts)}
                        <div className='amount-form-container'>
                          <p className='label'>{props.t('withdrawalamount')}</p>
                          <InputNumber
                            value={amountValue}
                            onValueChange={(e) => handleAmountChange(e.value)}
                            mode='currency'
                            currency='USD'
                            locale='en-US'
                            minFractionDigits={0}
                            maxFractionDigits={2}
                          />
                        </div>
                        {showMessage()}
                        <p className='failure'>{formErrorMessage}</p>
                        <div className='submit-button-container'>
                          {!withdrawLoading ? (
                            <Button
                              label={props.t('submit')}
                              onClick={() => {
                                withdrawValidateForm()
                              }}
                            />
                          ) : (
                            <Button loading />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {withdrawOptions === 2 && (
                  <>
                    {' '}
                    <img
                      src={BackIcon}
                      height={25}
                      width={25}
                      onClick={() => {
                        setWithDrawOptions(0)
                      }}
                      style={{ cursor: 'pointer' }}
                      alt=''
                    />
                    <div className='wire-withdraw-container'>
                      <WireWithdrawDialog
                        showWireWithdrawDialog={showWireWithdrawDialog}
                        toggleWireWithdrawDialog={toggleWireWithdrawDialog}
                        isSmallScreen={props.isSmallScreen}
                        accounts={props.accounts}
                        formatCurrency={formatCurrency}
                        t={props.t}
                        user={props.user}
                      />
                    </div>
                  </>
                )}
              </>
            </div>
          </>
        )
    }
  }

  return (
    <>
      <div
        className='ib-liquidate-dw-account-card'
        onClick={() => setShowDialog(true)}
      >
        <IoIosAlert size={56} />
        <p>{props?.t('investall_service_termination')}</p>
        <BiChevronRight size={48} style={{ alignSelf: 'end' }} />
      </div>
      {/* Show Accounts Dialog */}
      <Dialog
        header={props?.t('liquidate_accounts')}
        visible={showDialog}
        closable
        onHide={() => {
          setShowDialog(false)
          setSelectedAccount(null)
        }}
        draggable={false}
      >
        {renderCardsOrAccount()}
      </Dialog>
      {/* Liquidate Position Dialog */}
      <Dialog
        header={null}
        visible={showLiquidateConfirmation}
        closable={sellingPosition === 'loading' ? false : true}
        onHide={() => {
          closePositionDialog()
        }}
        className='liquidate-position'
      >
        {renderSellingPosition()}
      </Dialog>
      {/* Withdraw Dialog */}
      <Dialog
        header={null}
        visible={showWithdrawDialog}
        closable={withdrawPosition === 'loading' ? false : true}
        onHide={() => {
          setShowWithdrawDialog(false)
          setWithdrawPosition('main')
        }}
        className='liquidate-position'
      >
        {renderWithdrawFunds()}
      </Dialog>

      {/* Existing Withdraw Dialogs*/}
      <ConfirmationDialog
        isSmallScreen={props.isSmallScreen}
        toggleShowConfirmationDialog={toggleShowConfirmationDialog}
        showConfirmationDialog={showConfirmationDialog}
        action={submitAPI}
        name={props.t('submit')}
        successMessage={props.t('depositcompletedsuccessfully')}
        customHeader={props.t('confirmation')}
        customMessage={confirmMessage}
        t={props.t}
      />
    </>
  )
}
