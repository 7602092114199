import Axios from 'axios'
import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import IBLiquidateDWAccountsCard from './IBLiquidateDWAccountsCard'
import Endpoint from '../../../src/components/Endpoint'
import { Toast } from 'primereact/toast'
import { Onfido } from 'onfido-sdk-ui'

const languageMap = (key) => {
  switch (key) {
    case 'zh':
      return 'zh_CN'
    case 'pt':
      return 'pt_BR'
    default:
      return 'en_US'
  }
}

export default function IBAccountAdditionalInfoScreen(props) {
  const [showOnfidoDialog, setShowOnfidoDialog] = useState(false)
  const [onfidoSuccessMsg, setOnfidoSuccessMsg] = useState(false)
  const [loading, setLoading] = useState(false)
  const toastRef = useRef()

  const fetchData = async () => {
    try {
      // let res = await Axios.get(
      //   `${Endpoint}/mobile/alpaca/account/details?account_id=1e9894be-7d8b-4988-93e8-05314873f5fd`,
      //   // props?.accounts?.[0]?.accountId,
      //   {
      //     headers: {
      //       Authorization: 'Bearer ' + props?.jwtToken,
      //     },
      //   }
      // )
      // console.log('ACCOUNT DETAILS RES', res)

      const onfido_token_res = await Axios.get(
        Endpoint +
          '/mobile/alpaca/onfido/token?account_id=' +
          props?.alpacaAccounts?.[0]?.accountId,
        {
          headers: {
            Authorization: 'Bearer ' + props?.jwtToken,
          },
        }
      )
      console.log('onfido_token_res', onfido_token_res.data.output)

      Onfido.init({
        token: onfido_token_res.data.output,
        containerId: 'onfido-mount',
        language: languageMap(props?.getEmailObj?.prefLanguage),
        onComplete: async function (data) {
          console.log('everything is complete')
          console.log('data', data)
          const res_data = {
            outcome: 'USER_COMPLETED',
            token: onfido_token_res.data.output,
          }
          const res = await submitOnfidoData(res_data)
          console.log('kyc submit res', res)
          setShowOnfidoDialog(false)
          if (!res) {
            toastRef.current.show({
              severity: 'error',
              summary: 'Onfido KYC Error',
              detail:
                'There was an issue when submitting your data. Please try again',
              life: 3000,
            })
          } else {
            setOnfidoSuccessMsg(true)
          }
        },
        onError: function (data) {
          console.log('onfido error', data)
          toastRef.current.show({
            severity: 'error',
            summary: 'Onfido KYC Error',
            detail:
              data.message ??
              'There was an issue starting KYC upload. Please try again',
            life: 3000,
          })
          setShowOnfidoDialog(false)
        },
      })
      setShowOnfidoDialog(true)
    } catch (error) {
      console.log(error)
    }
  }

  const submitOnfidoData = async (body_data) => {
    try {
      const body = {
        outcome: body_data.outcome,
        token: body_data.token,
      }
      const { data } = await Axios.post(
        Endpoint +
          '/mobile/alpaca/onfido/status/update?account_id=' +
          props?.accounts?.[0]?.accountId,
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props?.jwtToken,
          },
        }
      )
      if (data.status === true) {
        return true
      } else {
        return false
      }
    } catch (error) {
      toastRef.current.show({
        severity: 'error',
        summary: 'KYC Error',
        detail: '',
        life: 3000,
      })
      return false
    }
  }

  return (
    <>
      <div className='ib-status-main-container'>
        <p className='ib-status-main-text'>
          {props?.t('additionalinformationrequired')}
        </p>
        <p className='ib-status-sub-text'>
          {props?.t('uploadadditionaldocumentsmsg')}
        </p>
        <div className='ib-status-additional-info-input'>
          <button onClick={() => fetchData()}>
            {props?.t('uploaddocument')}
          </button>
          <Dialog
            visible={showOnfidoDialog}
            onHide={() => setShowOnfidoDialog(false)}
            closable
            className='additional-documents-dialog'
            draggable={false}
          >
            <div
              id='onfido-mount'
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 40,
              }}
            ></div>
          </Dialog>
          <Dialog
            visible={onfidoSuccessMsg}
            onHide={() => setShowOnfidoDialog(false)}
            closable={false}
            className='additional-documents-dialog'
            draggable={false}
          >
            <div className='success-message'>
              <p className='message-main'>{props?.t('documentsuploaded')}</p>
              <p>{props?.t('documentsuploadedmsg')}</p>
              <Button
                label={props?.t('gotodashboard')}
                onClick={async () => {
                  setLoading(true)
                  await props?.getEmail()
                  await props?.getUserHoldings()
                  setOnfidoSuccessMsg(false)
                  setLoading(false)
                }}
                loading={loading}
              ></Button>
            </div>
          </Dialog>
        </div>
        <div className='dw-accounts-card'>
          {props?.driveWealthStatus && (
            <IBLiquidateDWAccountsCard
              {...props}
              user={props.user}
              filteredAccounts={props.accounts?.filter(
                (account) =>
                  account?.inst === 'DriveWealth' || account?.inst === 'Apex'
              )}
            />
          )}
        </div>
      </div>
      <Toast ref={toastRef} position='top-right' />
    </>
  )
}
