import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import Endpoint from '../Endpoint'
import { Toast } from 'primereact/toast' // Correct PrimeReact import
import IBLiquidateDWAccountsCard from './IBLiquidateDWAccountsCard'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import i18n from 'i18next'

export default function AlpacaDocumentUpload(props) {
  const toastRef = useRef(null)
  const toastRef2 = useRef(null)
  const [documentsToUpload, setDocumentsToUpload] = useState(null)
  const [kycMessage, setKycMessage] = useState('')
  const [showDocumentUpload, setShowDocumentUpload] = useState(false)

  // State to store Base64 strings for each document
  const [uploadedDocuments, setUploadedDocuments] = useState({})

  // State to store selected document types
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState({})

  // State to store document presets
  const [documentPresets, setDocumentPresets] = useState({})

  // State to control the upload confirmation dialog
  const [showDocumentUploadedModal, setShowDocumentUploadedModal] =
    useState(false)

  // Helper function to convert a file to Base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const fetchData = async () => {
    try {
      const account_id = props?.alpacaAccounts?.[0]?.accountId
      let res = await Axios.get(
        `${Endpoint}/ibkr/alpaca/account/details?account_id=${account_id}`,
        // props?.alpacaAccounts?.[0]?.accountId,
        {
          headers: {
            Authorization: 'Bearer ' + props?.jwtToken,
          },
        }
      )
      res.data.output.kyc_results.indeterminate = {
        ...res?.data?.output?.kyc_results?.indeterminate,
      }

      const add_info_variable =
        i18n.language !== 'en'
          ? 'additional_information_' + i18n.language
          : 'additional_information'
      setKycMessage(
        res?.data?.output?.kyc_results?.[add_info_variable] ??
          res?.data?.output?.kyc_results?.additional_information
      )

      const documentPresetList = res?.data?.output?.presets

      // Transform presets into a dictionary with label and value for Dropdown
      const preset_list = documentPresetList.reduce((acc, doc) => {
        acc[doc.code] = doc.options.map((option) => ({
          label: option,
          value: option,
        }))
        return acc
      }, {})

      setDocumentPresets(preset_list)

      const kyc_documents = Object.keys(
        res?.data?.output?.kyc_results?.indeterminate
      )
      // Filter kyc_documents to include only those present in preset_list
      const filteredDocuments = Object.keys(preset_list).filter((doc) =>
        kyc_documents.includes(doc)
      )

      console.log('Filtered Documents:', filteredDocuments)

      // Update the state with the filtered documents
      setDocumentsToUpload(filteredDocuments)
    } catch (e) {
      console.log('fetch account details error', e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // Handler for file selection
  const handleFileSelect = async (e, docType) => {
    const files = e.files
    if (files && files.length > 0) {
      const file = files[0] // Assuming one file per document
      try {
        const base64 = await fileToBase64(file)
        console.log(`Base64 for ${docType}:`, base64)

        // Update the uploadedDocuments state
        setUploadedDocuments((prev) => ({
          ...prev,
          [docType]: base64,
        }))
      } catch (error) {
        console.error('Error converting file to Base64:', error)
      }
    }
  }

  // Handler for Dropdown change
  const handleDocumentTypeChange = (e, docType) => {
    setSelectedDocumentTypes((prev) => ({
      ...prev,
      [docType]: e.value,
    }))
  }

  function extractMimeType(base64String) {
    const regex = /^data:(.+);base64,(.*)$/
    const matches = base64String.match(regex)
    if (matches) {
      return {
        mimeType: matches[1],
        base64: matches[2],
      }
    } else {
      // If no MIME type is found, return null for mimeType and the original string
      return {
        mimeType: null,
        base64: base64String,
      }
    }
  }

  // Handler to manually upload files (with validation)
  const handleUpload = async () => {
    const missingDocuments = []

    // Iterate over each document to check for missing selections or uploads
    documentsToUpload.forEach((doc) => {
      if (!selectedDocumentTypes[doc]) {
        missingDocuments.push(
          `${props?.t(doc)}: ${props?.t('selectDocumentType')}`
        )
      }
      if (!uploadedDocuments[doc]) {
        missingDocuments.push(`${props?.t(doc)}: ${props?.t('uploadImage')}`)
      }
    })

    if (missingDocuments.length > 0) {
      // Display error toast with all missing items
      toastRef.current.show({
        severity: 'error',
        summary: props?.t('upload_failed'),
        detail: props?.t('upload_error'), // PrimeReact's Toast supports HTML
        life: 3000,
        // No need for className if default styling is sufficient
      })
      return // Prevent further execution
    }

    // Proceed with the upload since all validations passed
    try {
      const documents_to_upload = []
      for (const [docType, base64] of Object.entries(uploadedDocuments)) {
        const selectedType = selectedDocumentTypes[docType]

        console.log('doctype', docType)
        console.log('selectedType', selectedType)
        console.log('base64', base64)

        const { mimeType, base64: cleanBase64 } = extractMimeType(base64)

        documents_to_upload.push({
          document_type: docType.toLowerCase(),
          document_sub_type: selectedType,
          mime_type: mimeType,
          content: cleanBase64,
        })
        // Optionally, add additional validation here if needed
      }

      const account_id = props?.alpacaAccounts?.[0]?.accountId

      const response = await Axios.post(
        `${Endpoint}/mobile/alpaca/upload/documents/${account_id}`,
        {
          documents: documents_to_upload,
        },
        {
          headers: {
            Authorization: 'Bearer ' + props?.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('response', response)
      // Show the upload confirmation dialog
      setShowDocumentUploadedModal(true)
      // Close the upload dialog after successful upload
      setShowDocumentUpload(false)
    } catch (error) {
      console.error('Error during upload:', error)
      // Show a general error toast if something goes wrong during upload
      toastRef.current.show({
        severity: 'error',
        summary: props?.t('upload_failed'),
        detail: props?.t('upload_error'),
        life: 3000,
      })
    }
  }

  return (
    <>
      <div className='ib-status-main-container'>
        <p className='ib-status-main-text'>
          {props?.t('additionalinformationrequired')}
        </p>
        <p
          className='ib-status-sub-text'
          style={{ maxWidth: 800, textAlign: 'center' }}
        >
          {/* {props?.t('uploadadditionaldocumentsmsg')} */}
          {kycMessage}
        </p>
        <div className='ib-status-additional-info-input'>
          <button onClick={() => setShowDocumentUpload(true)}>
            {props?.t('uploaddocument')}
          </button>
        </div>
        <div className='dw-accounts-card'>
          {props?.driveWealthStatus && (
            <IBLiquidateDWAccountsCard
              {...props}
              user={props.user}
              filteredAccounts={props.accounts?.filter(
                (account) =>
                  account?.inst === 'DriveWealth' || account?.inst === 'Apex'
              )}
            />
          )}
        </div>

        {/* Upload Documents Dialog */}
        <Dialog
          visible={showDocumentUpload}
          onHide={() => setShowDocumentUpload(false)}
          closable
          header={<p style={{ fontSize: 18 }}>{props?.t('uploaddocument')}</p>}
          className='additional-documents-dialog'
          draggable={false}
          footer={
            <div>
              <Button
                label={props?.t('submit')}
                icon='pi pi-check'
                onClick={handleUpload}
                className='p-button-primary'
              />
            </div>
          }
        >
          {documentsToUpload?.map((doc) => (
            <div key={doc} className='mb-3'>
              <h5>{props?.t(doc?.replace('_', ' '))}</h5>
              <div className='mb-2'>
                <Dropdown
                  value={selectedDocumentTypes[doc] || null}
                  onChange={(e) => handleDocumentTypeChange(e, doc)}
                  options={documentPresets[doc]}
                  placeholder={props?.t('select_document_type')}
                  style={{ width: '100%' }}
                />
              </div>
              <FileUpload
                name='file'
                customUpload
                uploadHandler={(e) => handleFileSelect(e, doc)}
                multiple={false}
                auto
                mode='advanced'
                chooseLabel={props.t('click_here_to_upload')}
                accept='.jpg,.png'
                showUploadButton={false} // Hides the Upload button
                showCancelButton={false} // Hides the Cancel button
                emptyTemplate={
                  <p className='p-m-0'>{props?.t('drag_and_drop_files')}</p>
                }
                className='w-full'
              />
            </div>
          ))}
          {/* PrimeReact Toast */}
          <Toast ref={toastRef} position='top-right' />
        </Dialog>

        {/* Document Uploaded Confirmation Dialog */}
        <Dialog
          visible={showDocumentUploadedModal}
          onHide={async () => {
            await props?.getEmail()
            await props?.getUserHoldings()
            setShowDocumentUploadedModal(false)
          }}
          closable={false}
          header={null}
          className='document-uploaded-dialog'
          draggable={false}
          footer={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                label={props?.t('okay')}
                onClick={async () => {
                  await props?.getEmail()
                  await props?.getUserHoldings()
                  setShowDocumentUploadedModal(false)
                }}
              />
            </div>
          }
        >
          <div className='text-center'>
            <h4 style={{ fontSize: 20, textAlign: 'center' }}>
              {props?.t('upload_successful')}
            </h4>
            <p>{props?.t('documents_uploaded_successfully')}</p>
            <p>{props?.t('status_update_in_progress')}</p>
          </div>
          <Toast ref={toastRef2} position='top-right' />
        </Dialog>
      </div>
    </>
  )
}
